import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';

import {
  DealVendorNote,
  DealVendorNoteTemplate
} from '~/models/DealVendorNote';

const paths = {
  show: 'vendors/deals/:deal_id/deal_vendor_notes/:id',
  template: 'vendors/deals/:deal_id/deal_vendor_notes/template'
};

type TShow = (
  dealId: number | null,
  id: number | null
) => SwrReturnType<DealVendorNote>;
type TTemplate = (
  dealId: number | null
) => SwrReturnType<DealVendorNoteTemplate>;
type ReturnType = {
  show: TShow;
  template: TTemplate;
};

export const useDealVendorNotes = (): ReturnType => {
  const show: TShow = (dealId: number | null, id: number | null) => {
    const { data, mutate, error, isLoading } = useSWR<DealVendorNote>(
      id === null
        ? null
        : paths.show
            .replace(':deal_id', String(dealId))
            .replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  const template: TTemplate = (dealId: number | null) => {
    const { data, mutate, error, isLoading } = useSWR<DealVendorNoteTemplate>(
      paths.template.replace(':deal_id', String(dealId))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  return { show, template };
};
