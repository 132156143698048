import React from 'react';
import { useDeals } from '~/hooks/vendors/useDeals';
import Styles from './statuses.module.scss';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { IN_ESTIMATION, SEND_ESTIMATE } from '~/models/Status';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const SendEstimate: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal } = useDeals().show(dealId);
  const { data: session } = useSessions().index();
  if (deal === null || session === null) return null;
  const skipEstimation = getOriginDealVendor(deal)?.skipEstimation;
  return (
    <div>
      <h3 className={Styles.Title}>見積りを送付しました。</h3>
      <p>施工項目確定をお待ち下さい</p>
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <StatusHandleVendorButtons
          forwardDisabled
          nextStatusId={SEND_ESTIMATE}
          prevStatusId={skipEstimation ? null : IN_ESTIMATION}
        />
      )}
    </div>
  );
};
