import useSWR from 'swr';
import { SwrReturnType } from '~/models/Api';

import { DealVendorNoteForDisplay } from '~/models/DealVendorNote';

const paths = {
  show: '/deals/:deal_id/deal_vendor_notes/:id'
};

type TShow = (
  dealId: number | null,
  id: number | null
) => SwrReturnType<DealVendorNoteForDisplay>;
type ReturnType = {
  show: TShow;
};
export const useDealVendorNotes = (): ReturnType => {
  const show: TShow = (dealId: number | null, id: number | null) => {
    const { data, mutate, error, isLoading } = useSWR<DealVendorNoteForDisplay>(
      id === null
        ? null
        : paths.show
            .replace(':deal_id', String(dealId))
            .replace(':id', String(id))
    );
    return {
      data: data ?? null,
      mutate,
      isLoading,
      error
    };
  };
  return { show };
};
