import React, { ReactNode } from 'react';
import { Table, Tbody, Text, Th, Thead, Tr } from '@itandi/itandi-bb-ui';
import { Status } from '~/models/Status';
import { NotificationListRow } from '~/components/common/notifications/settings/NotificationListRow';
import { Notification, NotificationEdit } from '~/models/Notification';
import { NskResponse } from '~/models/Api';
import { Subtype } from '~/models/Subtype';
import { LineUserAccount } from '~/models/LineUserAccount';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  notifications: Notification[];
  statuses: Status[];
  subtypes?: Subtype[] | null;
  lineUserAccounts?: LineUserAccount[] | null;
  useLineNotification: boolean;
  handleMutate: () => Promise<void>;
  handleUpdate: (
    notification: NotificationEdit,
    notificationId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (notification: Notification) => Promise<NskResponse<void>>;
}>;

export const NotificationList: React.FC<Props> = ({
  type,
  notifications,
  statuses,
  subtypes = null,
  lineUserAccounts = null,
  useLineNotification,
  handleMutate,
  handleUpdate,
  handleDestroy
}: Props) => {
  const renderRow = React.useCallback(
    (notification: Notification): ReactNode => (
      <NotificationListRow
        key={notification.id}
        handleDestroy={handleDestroy}
        handleMutate={handleMutate}
        handleUpdate={handleUpdate}
        lineUserAccounts={lineUserAccounts}
        notification={notification}
        statuses={statuses}
        subtypes={subtypes}
        type={type}
        useLineNotification={useLineNotification}
      />
    ),
    [
      handleDestroy,
      handleMutate,
      handleUpdate,
      lineUserAccounts,
      statuses,
      subtypes,
      type,
      useLineNotification
    ]
  );
  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <Text size="S" weight="Bold">
              案件種別
            </Text>
          </Th>
          <Th>
            <Text size="S" weight="Bold">
              通知トリガー
            </Text>
          </Th>
          <Th>
            <Text size="S" weight="Bold">
              案件状況
            </Text>
          </Th>
          <Th>
            <Text size="S" weight="Bold">
              通知タイプ
            </Text>
          </Th>
          <Th>
            <Text size="S" weight="Bold">
              通知先
            </Text>
          </Th>
          <Th>
            <Text size="S" weight="Bold">
              通知タイトル
            </Text>
          </Th>
          <Th width="6rem">
            <Text size="S" weight="Bold">
              操作
            </Text>
          </Th>
        </Tr>
      </Thead>
      <Tbody>{notifications.map(renderRow)}</Tbody>
    </Table>
  );
};
