import { DealEstimation } from '~/models/DealEstimate';
import { NskResponse } from '~/models/Api';
import {
  deleteRequest,
  postRequest,
  putRequest
} from '~/utils/api/api_handler';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';

const paths = {
  create: '/vendors/deals/:deal_id/deal_estimations',
  update: '/vendors/deals/:deal_id/deal_estimations/:id',
  destroy: '/vendors/deals/:deal_id/deal_estimations/:id',
  updateSeq: '/vendors/deals/:deal_id/deal_estimations/update_seq',
  updateSeqV2: '/vendors/deals/:deal_id/deal_estimations/update_seq_v2'
};

export const create = async (
  dealEstimation: DealConstructionEdit,
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    dealEstimation
  });

export const update = async (
  dealEstimation: DealConstructionEdit,
  dealEstimationId: number,
  dealId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':id', String(dealEstimationId))
      .replace(':deal_id', String(dealId)),
    { dealEstimation }
  );

export const destroy = async (
  dealEstimation: DealEstimation,
  dealId: number
): Promise<NskResponse<void>> =>
  deleteRequest(
    paths.destroy
      .replace(':id', String(dealEstimation.id))
      .replace(':deal_id', String(dealId))
  );

export const updateSeq = async (
  dealEstimations: DealConstruction[],
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeq.replace(':deal_id', String(dealId)), {
    dealEstimations
  });

export const updateSeqV2 = async (
  dealEstimations: DealConstruction[],
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.updateSeqV2.replace(':deal_id', String(dealId)), {
    dealEstimations
  });
