import React from 'react';
import { toast } from 'react-toastify';
import { Box, Button, Grid } from '@itandi/itandi-bb-ui';
import { useStatuses } from '~/hooks/useStatuses';
import { useSubtypes } from '~/hooks/useSubtypes';
import { NotificationList } from '~/components/common/notifications/settings/NotificationList';
import { Notification, NotificationEdit } from '~/models/Notification';
import { NskResponse } from '~/models/Api';
import { create, destroy, update } from '~/utils/api/CompanyNotification';
import { useCompanyNotifications } from '~/hooks/useCompanyNotifications';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { isSuccess } from '~/utils/api/api_handler';
import SettingStyles from '../SettingStyle.module.scss';
import { NotificationModal } from '~/components/common/notifications/settings/NotificationModal';

export const NotificationSetting: React.FC = () => {
  const { data: notifications, mutate: mutateNotifications } =
    useCompanyNotifications().index();
  const { data: statuses } = useStatuses().index();
  const { data: subtypes } = useSubtypes().useIndex();
  const {
    isOpen: openNotificationModal,
    handleOpen: handleOpenNotificationModal,
    handleClose: handleCloseNotificationModal
  } = useOpenHandler();
  const handleCreate = React.useCallback(
    async (notification: NotificationEdit): Promise<NskResponse<void>> =>
      create(notification),
    []
  );
  const handleUpdate = React.useCallback(
    async (
      notification: NotificationEdit,
      notificationId: number
    ): Promise<NskResponse<void>> => update(notification, notificationId),
    []
  );
  const handleDestroy = React.useCallback(
    async (notification: Notification): Promise<NskResponse<void>> =>
      destroy(notification),
    []
  );
  const handleMutate = React.useCallback(async () => {
    mutateNotifications && (await mutateNotifications());
  }, [mutateNotifications]);
  const handleAcceptSubmit = React.useCallback(
    async (notification: NotificationEdit): Promise<void> => {
      const response = await handleCreate(notification);
      if (isSuccess(response)) {
        await handleMutate();
        handleCloseNotificationModal();
        toast.success('通知設定を追加しました。');
        return;
      }
      toast.error('通知設定の追加に失敗しました。');
    },
    [handleMutate, handleCloseNotificationModal, handleCreate]
  );
  if (notifications === null || statuses === null) {
    return null;
  }
  return (
    <>
      <Grid>
        <Box>
          <h3 className={SettingStyles.SettingTitle}>通知設定</h3>
        </Box>
        <Box>
          <NotificationList
            handleDestroy={handleDestroy}
            handleMutate={handleMutate}
            handleUpdate={handleUpdate}
            notifications={notifications}
            statuses={statuses}
            subtypes={subtypes}
            type="CompanyNotification"
            useLineNotification={false}
          />
        </Box>
        <Box marginTop={8}>
          <Button onClick={handleOpenNotificationModal} variant="secondary">
            通知設定の追加
          </Button>
        </Box>
      </Grid>
      <NotificationModal
        handleAcceptSubmit={handleAcceptSubmit}
        handleClose={handleCloseNotificationModal}
        isOpen={openNotificationModal}
        lineUserAccounts={null}
        statuses={statuses}
        subtypes={subtypes}
        type="CompanyNotification"
        useLineNotification={false}
      />
    </>
  );
};
