import React from 'react';
import {
  Td,
  Tr,
  Dropdown,
  DropdownList,
  DropdownMenu,
  Box,
  ConfirmModal,
  Text
} from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import {
  ADDRESS_TYPE_TO_LABELS,
  COMPANY_TRIGGER_TYPE_SELECT_OPTIONS,
  DEAL_STATE_LABELS,
  DEAL_TYPE_LABELS,
  Notification,
  NotificationEdit,
  NOTIFY_TYPE_LABELS,
  SEND_TYPE_LABELS,
  TRIGGER_DATE_TYPE_LABELS,
  VENDOR_TRIGGER_TYPE_SELECT_OPTIONS
} from '~/models/Notification';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { NotificationModal } from '~/components/common/notifications/settings/NotificationModal';
import { isSuccess } from '~/utils/api/api_handler';
import { NskResponse } from '~/models/Api';
import { Status } from '~/models/Status';
import { Subtype } from '~/models/Subtype';
import { LineUserAccount } from '~/models/LineUserAccount';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  statuses: Status[];
  subtypes: Subtype[] | null;
  lineUserAccounts: LineUserAccount[] | null;
  useLineNotification: boolean;
  notification: Notification;
  handleMutate: () => Promise<void>;
  handleUpdate: (
    notification: NotificationEdit,
    notificationId: number
  ) => Promise<NskResponse<void>>;
  handleDestroy: (notification: Notification) => Promise<NskResponse<void>>;
}>;

const getNotifyReservationDaysLabel = (days: number): string => {
  if (days === 0) {
    return '当日';
  }
  if (days < 0) {
    return `${days * -1}日前`;
  }
  return `${days}日後`;
};

const isBlank = (text: string | null): boolean => {
  if (text === null) {
    return true;
  }
  return text.trim() === '';
};

export const NotificationListRow: React.FC<Props> = ({
  type,
  statuses,
  subtypes,
  lineUserAccounts,
  useLineNotification,
  notification,
  handleMutate,
  handleUpdate,
  handleDestroy
}: Props) => {
  const {
    isOpen: isOpenEdit,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit
  } = useOpenHandler();
  const {
    isOpen: isOpenDelete,
    handleOpen: handleOpenDelete,
    handleClose: handleCloseDelete
  } = useOpenHandler();
  const statusLabel = React.useMemo(() => {
    if (statuses === null || notification.triggerType !== 'status') {
      return '';
    }
    const status =
      statuses.find((s) => s.id === notification.triggerStatusId) ?? null;
    return status === null ? '' : status.name;
  }, [statuses, notification]);
  const handleUpdateAccept = React.useCallback(
    async (notificationEdit: NotificationEdit): Promise<void> => {
      const response = await handleUpdate(notificationEdit, notification.id);
      if (isSuccess(response)) {
        await handleMutate();
        handleCloseEdit();
        toast.success('通知設定を更新しました。');
        return;
      }
      toast.error('通知設定の更新に失敗しました。');
    },
    [handleMutate, handleCloseEdit, notification, handleUpdate]
  );
  const handleDeleteAccept = React.useCallback(async () => {
    const response = await handleDestroy(notification);
    if (isSuccess(response)) {
      await handleMutate();
      handleCloseDelete();
      toast.success('通知設定を削除しました');
      return;
    }
    toast.error('エラーが発生しました');
  }, [notification, handleMutate, handleCloseDelete, handleDestroy]);
  const subtype = subtypes?.find((s) => s.id === notification.dealSubtypeId);
  if (statuses === null) {
    return null;
  }
  const {
    triggerType,
    triggerDateType,
    notifyReservationDays,
    dealEstimationState,
    dealInspectionReportState,
    dealCompletionReportState,
    dealConstructionScheduleState
  } = notification;
  return (
    <>
      <Tr>
        <Td>
          <Box>{DEAL_TYPE_LABELS[notification.dealType]}</Box>
          {notification.dealType === 'other' &&
            type === 'CompanyNotification' && (
              <Box marginTop=".25rem">
                <Text color="Sub" size="XS">
                  {subtype && subtype.name}
                </Text>
              </Box>
            )}
        </Td>
        <Td>
          {type === 'CompanyNotification' && (
            <Box>{COMPANY_TRIGGER_TYPE_SELECT_OPTIONS[triggerType]?.label}</Box>
          )}
          {type === 'VendorNotification' && (
            <Box>{VENDOR_TRIGGER_TYPE_SELECT_OPTIONS[triggerType]?.label}</Box>
          )}
          {triggerType === 'status' && (
            <Box marginTop=".25rem">
              <Text color="Sub" size="XS">
                {statusLabel}
              </Text>
            </Box>
          )}
          {triggerType === 'specific_date' && triggerDateType && (
            <Box marginTop=".25rem">
              <Text color="Sub" size="XS">
                {TRIGGER_DATE_TYPE_LABELS[triggerDateType]}
              </Text>
            </Box>
          )}
        </Td>
        <Td>
          {dealEstimationState === 'no_setting' &&
            dealInspectionReportState === 'no_setting' &&
            dealCompletionReportState === 'no_setting' &&
            dealConstructionScheduleState === 'no_setting' && (
              <Box>登録状況に関わらず通知</Box>
            )}
          {dealEstimationState !== 'no_setting' && (
            <Box marginTop=".25rem">
              <Text size="XS">
                見積り: {DEAL_STATE_LABELS[dealEstimationState]}
              </Text>
            </Box>
          )}
          {dealInspectionReportState !== 'no_setting' && (
            <Box marginTop=".25rem">
              <Text size="XS">
                立会報告画像: {DEAL_STATE_LABELS[dealInspectionReportState]}
              </Text>
            </Box>
          )}
          {dealCompletionReportState !== 'no_setting' && (
            <Box marginTop=".25rem">
              <Text size="XS">
                完了報告画像: {DEAL_STATE_LABELS[dealCompletionReportState]}
              </Text>
            </Box>
          )}
          {dealConstructionScheduleState !== 'no_setting' && (
            <Box marginTop=".25rem">
              <Text size="XS">
                工事日程の入力状況:
                {DEAL_STATE_LABELS[dealConstructionScheduleState]}
              </Text>
            </Box>
          )}
        </Td>
        <Td>
          <Box>{NOTIFY_TYPE_LABELS[notification.notifyType]}</Box>
          {notification.notifyType === 'reservation' &&
            notifyReservationDays && (
              <Box marginTop=".25rem">
                <Text color="Sub" size="XS">
                  {getNotifyReservationDaysLabel(notifyReservationDays)}
                </Text>
              </Box>
            )}
        </Td>
        <Td>
          <Box>{SEND_TYPE_LABELS[notification.sendType]}</Box>
          {notification.sendType === 'mail' && (
            <Box marginTop=".25rem">
              <Text color="Sub" size="XS">
                {
                  ADDRESS_TYPE_TO_LABELS[
                    notification.notificationMails[0]?.addressType
                  ]
                }
                {notification.notificationMails.length > 1 && ' 等'}
              </Text>
            </Box>
          )}
        </Td>
        <Td>
          {isBlank(notification.title)
            ? notification.content
            : notification.title}
        </Td>
        <Td>
          <Box marginBottom="1rem" marginTop="1rem">
            <Dropdown label="操作">
              <DropdownList>
                <DropdownMenu onClick={handleOpenEdit}>編集</DropdownMenu>
                <DropdownMenu danger onClick={handleOpenDelete}>
                  削除
                </DropdownMenu>
              </DropdownList>
            </Dropdown>
          </Box>
        </Td>
      </Tr>
      <NotificationModal
        handleAcceptSubmit={handleUpdateAccept}
        handleClose={handleCloseEdit}
        isOpen={isOpenEdit}
        lineUserAccounts={lineUserAccounts}
        notification={notification}
        statuses={statuses}
        subtypes={subtypes}
        type={type}
        useLineNotification={useLineNotification}
      />
      <ConfirmModal
        handleClose={handleCloseDelete}
        handleSubmit={handleDeleteAccept}
        headerTitle="削除確認"
        isOpen={isOpenDelete}
        submitLabel="削除する"
        useDefaultFooterCancel
      >
        通知を削除してよろしいですか？
      </ConfirmModal>
    </>
  );
};
