import React from 'react';
import { useRouter } from 'next/router';
import { Alert } from '@itandi/itandi-bb-ui';
import { toast } from 'react-toastify';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Styles from './Page.module.scss';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import { PATH } from '~/pages/vendors/deals/[id]';
import { useDeals } from '~/hooks/vendors/useDeals';
import Loading from '~/components/common/parts/loading/Loading';
import { TopArea } from '~/components/common/reports/TopArea';
import { ReportImagesArea } from '~/components/common/reports/ReportImagesArea';
import {
  create as postCompletionReportImages,
  destroy,
  update,
  updateSeq,
  getDownloadPath as getDownloadImagePath,
  bulkDelete,
  bulkUpdate
} from '~/utils/api/vendors/deals/DealCompletionReportImage';
import { isSuccess } from '~/utils/api/api_handler';
import {
  DealReportBulkUpdateParams,
  DealReportImage,
  NUMBER_OF_DELETE_AT_ONCE,
  NUMBER_OF_UPLOAD_AT_ONCE
} from '~/models/DealReportImage';
import { NskResponse } from '~/models/Api';
import { getDownloadPath as getDownloadPdfPath } from '~/utils/api/vendors/deals/DealCompletionReport';
import { ReportImagesAreaSp } from '~/components/common/reports/ReportImagesAreaSp';
import { useDealCompletionReportImages } from '~/hooks/vendors/deals/useDealCompletionReportImages';
import { DealLayoutFooter } from '~/components/common/layouts/deals/DealLayoutFooter';
import { isSubVendor } from '~/models/DealVendorCompany';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { useSessions } from '~/hooks/vendors/useSessions';
import { create as postComplete } from '~/utils/api/vendors/deals/DealCompletionReportComplete';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const {
    isOpen: showConfirm,
    handleOpen: handleOpenConfirm,
    handleClose: handleCloseConfirm
  } = useOpenHandler();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [selectedImageIds, setSelectedImageIds] = React.useState<
    ReadonlyArray<number>
  >([]);
  const { data: session } = useSessions().index();
  const router = useRouter();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: reportImages, mutate: mutateReportImages } =
    useDealCompletionReportImages().index(id);
  const vendorCompanyId = session?.vendorCompany.id ?? null;
  if (deal === null || reportImages === null || vendorCompanyId === null) {
    return <Loading isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal.id), caption: '案件詳細', active: false },
    { path: '', caption: '完了報告画像の登録', active: true }
  ];
  const handleMutateReportImages = async (): Promise<void> => {
    mutateReportImages && (await mutateReportImages());
  };
  const handleDrop = async (files: File[]): Promise<void> => {
    setProgress(0);
    setUploading(true);
    const arrFiles: File[][] = [];
    const totalFiles: number = files.length;
    let finishedFiles = 0;
    while (files.length > 0) {
      arrFiles.push(files.splice(0, NUMBER_OF_UPLOAD_AT_ONCE));
    }
    for await (const sendFiles of arrFiles) {
      const result = await postCompletionReportImages(sendFiles, deal.id);
      if (isSuccess(result)) {
        mutateReportImages && (await mutateReportImages());
      } else {
        toast.error('エラーが発生しました');
        return;
      }
      finishedFiles += sendFiles.length;
      setProgress(Math.round((finishedFiles / totalFiles) * 100));
      mutateReportImages && (await mutateReportImages());
    }
    setUploading(false);
    toast.success('画像を登録しました');
  };
  const handleUpdate = async (
    submitValue: DealReportImage
  ): Promise<NskResponse<void>> => update(submitValue);
  const handleBulkUpdate = async (
    submitValue: DealReportBulkUpdateParams
  ): Promise<void> => {
    const result = await bulkUpdate(deal.id, selectedImageIds, submitValue);
    if (isSuccess(result)) {
      mutateReportImages && (await mutateReportImages());
      toast.success('更新しました');
    } else {
      toast.error('エラーが発生しました');
    }
  };
  const handleDestroy = async (
    reportImage: DealReportImage
  ): Promise<NskResponse<void>> => destroy(reportImage);
  const handleBulkDelete = async (): Promise<void> => {
    if (selectedImageIds.length === 0) {
      return;
    }
    setProgress(0);
    setDeleting(true);
    const imageIdArray: number[][] = [];
    const totalFiles: number = selectedImageIds.length;
    let finishedFiles = 0;
    for (
      let i = 0, j = selectedImageIds.length;
      i < j;
      i += NUMBER_OF_DELETE_AT_ONCE
    ) {
      imageIdArray.push(
        selectedImageIds.slice(i, i + NUMBER_OF_DELETE_AT_ONCE)
      );
    }
    for await (const ids of imageIdArray) {
      const result = await bulkDelete(deal.id, ids);
      if (isSuccess(result)) {
        mutateReportImages && (await mutateReportImages());
      } else {
        toast.error('エラーが発生しました');
        return;
      }
      finishedFiles += ids.length;
      setProgress(Math.round((finishedFiles / totalFiles) * 100));
      mutateReportImages && (await mutateReportImages());
    }
    setDeleting(false);
    setSelectedImageIds([]);
    toast.success('画像を削除しました');
  };
  const handleUpdateSeq = async (
    newOrderItems: DealReportImage[]
  ): Promise<void> => {
    updateSeq(newOrderItems, Number(id)).then();
  };
  const handleDownloadPdf = (): void => {
    const path = getDownloadPdfPath(Number(id));
    window.open(path);
  };
  const handleDownloadImage = (reportImage: DealReportImage): void => {
    const path = getDownloadImagePath(reportImage);
    window.open(path);
  };
  const handleSubmitComplete = async (): Promise<void> => {
    const result = await postComplete(deal.id);
    if (isSuccess(result)) {
      await router.push(PATH(deal.id));
      toast.success('完了しました');
      return;
    }
    toast.error(`エラーが発生しました。${result.message}`);
  };
  const onChangeSelectedImage = (selectedImageId: number): void => {
    if (selectedImageIds.includes(selectedImageId)) {
      setSelectedImageIds(
        selectedImageIds.filter((imageId) => imageId !== selectedImageId)
      );
    } else {
      setSelectedImageIds(selectedImageIds.concat([selectedImageId]));
    }
  };
  const handleResetSelectedIds = (): void => setSelectedImageIds([]);
  const handleSelectAllImages = (): void => {
    setSelectedImageIds(reportImages.map((image: DealReportImage) => image.id));
  };
  return (
    <VendorLayoutV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor
        noBackground
      >
        <div className={Styles.MainWrapper}>
          <h2 className={Styles.ReportTitle}>完了報告画像の登録</h2>
          {uploading && (
            <div className={Styles.ProgressBar}>
              <p className={Styles.message}>
                <Alert variant="warning">
                  現在写真をアップロード中です。
                  <br />
                  画面を移動すると、アップロードが正常に完了しない場合があります。
                </Alert>
              </p>
              <ProgressBar label={`${progress}%`} now={progress} />
            </div>
          )}
          {deleting && (
            <div className={Styles.DeleteProgressBar}>
              <p className={Styles.message}>
                <Alert variant="warning">
                  現在写真を削除中です。
                  <br />
                  画面を移動すると、削除が正常に完了しない場合があります。
                </Alert>
              </p>
              <ProgressBar label={`${progress}%`} now={progress} />
            </div>
          )}
          {!deleting && !uploading && <TopArea handleDrop={handleDrop} />}
          <ReportImagesArea
            companyType="vendor"
            handleBulkDelete={handleBulkDelete}
            handleBulkUpdate={handleBulkUpdate}
            handleDestroy={handleDestroy}
            handleDownloadImage={handleDownloadImage}
            handleDownloadPdf={handleDownloadPdf}
            handleMutateReportImages={handleMutateReportImages}
            handleResetSelectedIds={handleResetSelectedIds}
            handleSelectAllImages={handleSelectAllImages}
            handleUpdate={handleUpdate}
            handleUpdateSeq={handleUpdateSeq}
            onChangeSelectedImage={onChangeSelectedImage}
            reportImages={reportImages}
            selectedImageIds={selectedImageIds}
          />
          <ReportImagesAreaSp
            companyType="vendor"
            handleBulkDelete={handleBulkDelete}
            handleBulkUpdate={handleBulkUpdate}
            handleDestroy={handleDestroy}
            handleDownloadImage={handleDownloadImage}
            handleDownloadPdf={handleDownloadPdf}
            handleMutateReportImages={handleMutateReportImages}
            handleResetSelectedIds={handleResetSelectedIds}
            handleSelectAllImages={handleSelectAllImages}
            handleUpdate={handleUpdate}
            handleUpdateSeq={handleUpdateSeq}
            onChangeSelectedImage={onChangeSelectedImage}
            reportImages={reportImages}
            selectedImageIds={selectedImageIds}
          />
        </div>
        {selectedImageIds.length === 0 && (
          <DealLayoutFooter>
            {isSubVendor(deal, vendorCompanyId) ? (
              <button
                className={Styles.CompleteButton}
                onClick={handleOpenConfirm}
                type="button"
              >
                完了して通知する
              </button>
            ) : (
              <button
                className={Styles.CompleteButton}
                onClick={handleSubmitComplete}
                type="button"
              >
                完了して戻る
              </button>
            )}
            <ConfirmModal
              actionText="完了"
              confirmType="save"
              handleAccept={handleSubmitComplete}
              handleCancel={handleCloseConfirm}
              isOpen={showConfirm}
            >
              完了してよろしいですか？
            </ConfirmModal>
          </DealLayoutFooter>
        )}
      </DealLayout>
    </VendorLayoutV2>
  );
};
