import React, { ReactElement } from 'react';
import {
  Td,
  Tr,
  Chip,
  StatusIcon,
  Text,
  TextLink,
  colors,
  Flex,
  Box,
  Grid,
  RiFilePaperLine
} from '@itandi/itandi-bb-ui';
import { FaSquare } from 'react-icons/fa';
import { DealIndexVendor } from '~/models/Deal';
import { PATH as VENDOR_DEAL_PATH } from '~/pages/vendors/deals/[id]';
import { DealTypeChip } from '~/components/common/parts/chip/DealTypeChip';
import { formatHoursAndMinutes, formatMonthAndDay } from '~/utils/DateUtil';
import { processedHistory } from '~/models/DealHistory';
import { truncate } from '~/utils/StringUtil';
import { statusColor } from '~/models/Status';

type Props = {
  deal: DealIndexVendor;
  statusNames: { [p: string]: string };
};

export const DealTableRow: React.FC<Props> = ({ deal, statusNames }: Props) => {
  const displayDate = (
    date: string | null,
    displayTime: boolean
  ): ReactElement => {
    if (date === null) {
      return (
        <Flex>
          <Box>
            <Text color="Sub" size="S">
              未定
            </Text>
          </Box>
          <Box marginTop={2}>
            <StatusIcon variant="Alert" />
          </Box>
        </Flex>
      );
    }
    return (
      <Grid alignItems="center" gap={4}>
        <Box>
          <Text size="S">{formatMonthAndDay(date)}</Text>
        </Box>
        {displayTime && (
          <Box>
            <Text size="S">{formatHoursAndMinutes(date)}</Text>
          </Box>
        )}
      </Grid>
    );
  };
  const historyRegistered = (
    historyType: 'estimations_created' | 'completion_report_created'
  ): ReactElement => (
    <>
      {processedHistory(deal, historyType) ? (
        <Text size="S">登録済み</Text>
      ) : (
        <Flex>
          <Box>
            <Text color="Sub" size="S">
              {historyType === 'estimations_created' ? (
                <>未登録</>
              ) : (
                <>{deal.completionReportImageRequired ? '未登録' : '任意'}</>
              )}
            </Text>
          </Box>
          {(historyType === 'estimations_created' ||
            (historyType === 'completion_report_created' &&
              deal.completionReportImageRequired)) && (
            <Box marginTop={2}>
              <StatusIcon variant="Alert" />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
  return (
    <Tr>
      <Td textAlign="Left" verticalAlign="Top">
        <Box marginY={2}>
          <Chip
            iconColor={statusColor[deal.status]}
            iconType={FaSquare}
            label={statusNames[deal.status]}
          />
        </Box>
        {deal.unreadMessageCount > 0 && (
          <Box marginY={2}>
            <Chip
              background={colors['semantic-negative']}
              borderColor={null}
              label={`未読${deal.unreadMessageCount}`}
              textColor={colors['itandi-white']}
            />
          </Box>
        )}
        {deal.hasTemporaryDealCancel && (
          <Box marginY={2}>
            <Chip
              background={colors['semantic-negative']}
              borderColor={null}
              iconColor={colors['itandi-white']}
              iconType={RiFilePaperLine}
              label="取下申請あり"
              textColor={colors['itandi-white']}
            />
          </Box>
        )}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box>
          <Flex gap={2}>
            <DealTypeChip
              dealSubtype={deal.dealSubtype}
              dealType={deal.dealType}
            />
          </Flex>
        </Box>
        <Box>
          <TextLink
            href={VENDOR_DEAL_PATH(deal.id)}
            target="_blank"
            variant="PrimaryBold"
          >
            {`${truncate(deal.name, 30)} ${deal.roomNumber || ''}`}
          </TextLink>
          <Flex gap={4}>
            <Box>
              <Text color="Sub" display="Block" size="S">
                {truncate(deal.address, 35)}
              </Text>
            </Box>
            {deal.tenantName && (
              <Box>
                <Text color="Sub" display="Block" size="S">
                  {`${truncate(deal.tenantName, 15)} 様`}
                </Text>
              </Box>
            )}
          </Flex>
        </Box>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box maxW="8rem">
          <Text display="Block" size="S">
            {deal.maintenanceCompanyName}
          </Text>
          <Text color="Sub" display="Block" size="XS">
            {deal.maintenanceCompanyStoreName}
          </Text>
          <Text color="Sub" display="Block" size="XS">
            {truncate(deal.staff.name, 35)}
          </Text>
        </Box>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box maxW="8rem">
          <Text display="Block" size="S">
            {deal.originVendorCompany.name}
          </Text>
          {deal.originVendorUserName ? (
            <Text color="Sub" display="Block" size="XS">
              {truncate(deal.originVendorUserName, 35)}
            </Text>
          ) : (
            <Flex>
              <Box>
                <Text color="Sub" display="Block" size="XS">
                  担当者設定なし
                </Text>
              </Box>
              <Box marginTop={1}>
                <StatusIcon variant="Alert" />
              </Box>
            </Flex>
          )}
        </Box>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box maxW="8rem">
          {deal.subVendorCompany === null ? (
            <Flex>
              <Box>
                <Text color="Sub" display="Block" size="S">
                  実施会社未指定
                </Text>
              </Box>
            </Flex>
          ) : (
            <Text display="Block" size="S">
              {deal.subVendorCompany.name}
            </Text>
          )}
          {deal.subVendorUserName ? (
            <Text color="Sub" display="Block" size="XS">
              {truncate(deal.subVendorUserName, 35)}
            </Text>
          ) : (
            <Box>
              <Text color="Sub" display="Block" size="XS">
                担当者設定なし
              </Text>
            </Box>
          )}
        </Box>
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.terminationDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.inspectionDate, true)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.constructionStartDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.constructionEndDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.preferredDate, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        {displayDate(deal.createdAt, false)}
      </Td>
      <Td textAlign="Left" verticalAlign="Top">
        <Box>{historyRegistered('estimations_created')}</Box>
        <Box>{historyRegistered('completion_report_created')}</Box>
      </Td>
    </Tr>
  );
};
