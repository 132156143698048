import React from 'react';
import Loader from '~/components/common/parts/loading/Loading';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/vendors/deals';
import { PATH as DEAL_PATH } from '~/pages/vendors/deals/[id]';
import { VendorLayoutV2 } from '~/components/common/layouts/VendorLayoutV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useMessages } from '~/hooks/vendors/deals/useMessages';
import { ChatPageContainer } from '~/components/common/chat/ChatPageContainer';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useSessions } from '~/hooks/vendors/useSessions';
import { getOriginVendor, isOriginVendor } from '~/models/DealVendorCompany';
import { allowMessageByVendorCompany } from '~/models/VendorSession';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: session } = useSessions().index();
  const { data: deal, isLoading: isLoadingDeal } = useDeals().show(id);
  const {
    data: messages,
    mutate: mutateMessages,
    isLoading: isLoadingMessages
  } = useMessages().index(id);
  const handleMutate = React.useCallback(async () => {
    mutateMessages && (await mutateMessages());
  }, [mutateMessages]);

  const isOrigin = React.useMemo(() => {
    if (session === null || deal === null) {
      return false;
    }
    return isOriginVendor(deal, session.vendorCompany.id);
  }, [deal, session]);
  const showMessage = React.useMemo(() => {
    if (session === null || deal === null) {
      return false;
    }
    if (isOrigin) {
      return true;
    }
    const originVendor = getOriginVendor(deal);
    if (originVendor === null) {
      return false;
    }
    return allowMessageByVendorCompany(session, originVendor.id);
  }, [deal, isOrigin, session]);
  if (
    isLoadingDeal ||
    isLoadingMessages ||
    deal === null ||
    messages === null
  ) {
    return <Loader isLoading />;
  }
  if (!showMessage) {
    return null;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: DEAL_PATH(deal.id), caption: '案件詳細', active: false },
    { path: null, caption: '管理会社とチャット', active: true }
  ];
  return (
    <VendorLayoutV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor
        useSimpleSpHeader
      >
        <ChatPageContainer
          deal={deal}
          handleMutate={handleMutate}
          messages={messages}
          speaker={isOrigin ? 'vendor_company' : 'partner_vendor_company'}
        />
      </DealLayout>
    </VendorLayoutV2>
  );
};
