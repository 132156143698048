import React from 'react';
import {
  Button,
  FormControl,
  FormControlText,
  InputText,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modalv2,
  RiEdit2Line
} from '@itandi/itandi-bb-ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import {
  DealVendorNote,
  DealVendorNoteParam,
  validationSchema,
  buildDealVendorNoteParam,
  DealVendorNoteTemplate
} from '~/models/DealVendorNote';
import { Deal } from '~/models/Deal';
import { isSuccess } from '~/utils/api/api_handler';
import { create, update } from '~/utils/api/vendors/deals/DealVendorNote';

type Props = {
  deal: Deal;
  dealVendorNote: DealVendorNote | null;
  dealVendorNoteTemplate: DealVendorNoteTemplate;
  isOpen: boolean;
  handleClose: () => void;
  handleMutate: () => Promise<void>;
};

export const DealVendorNoteModal: React.FC<Props> = ({
  deal,
  dealVendorNote,
  dealVendorNoteTemplate,
  isOpen,
  handleClose,
  handleMutate
}: Props) => {
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    formState: { errors }
  } = useForm<DealVendorNoteParam>({
    resolver: yupResolver(validationSchema),
    defaultValues: dealVendorNote
      ? buildDealVendorNoteParam(dealVendorNote)
      : {
          note: dealVendorNoteTemplate.dealVendorNoteTemplate || '',
          version: null
        }
  });
  const createMode = dealVendorNote === null;
  const handleModalSubmit = React.useCallback(() => {
    handleSubmit(async (submitValue: DealVendorNoteParam) => {
      const response = createMode
        ? await create(submitValue, deal.id)
        : await update(submitValue, dealVendorNote.id, deal.id);
      if (isSuccess(response)) {
        await handleMutate();
        handleClose();
        if (createMode) {
          toast.success('作成しました');
        } else {
          toast.success('更新しました');
          // versionを1つ進める
          setValue('version', (submitValue.version ?? 0) + 1);
        }
        return;
      }
      toast.error(`${response.message}`);
    })();
  }, [
    handleSubmit,
    createMode,
    deal.id,
    dealVendorNote,
    handleClose,
    handleMutate,
    setValue
  ]);

  return (
    <Modalv2 handleClose={handleClose} isOpen={isOpen} size="Large">
      <ModalHeader
        description="施工会社/実施会社双方から編集可能です。管理会社へ共有されます。"
        icon={RiEdit2Line}
        title="案件メモの登録"
      />
      <ModalBody>
        <FormControl
          chip="任意"
          isInvalid={errors.note != null}
          label="案件メモ"
          variant="Vertical"
        >
          <InputText
            autoHeightLimit={20}
            isInvalid={errors.note != null}
            placeholder="共有したいメモを記載してください"
            rows={8}
            {...register('note')}
          />
          {createMode && dealVendorNoteTemplate.dealVendorNoteTemplate && (
            <FormControlText help="テンプレートが適応されました。" />
          )}
          <FormControlText help="改行、URLはマークダウンで記載可能です。改行は半角スペース2つ、URLは[リンク名](URL)で記載可能です" />
          {errors.note != null && (
            <FormControlText error={errors.note.message || ''} />
          )}
        </FormControl>
      </ModalBody>
      <ModalFooter
        leftArea={
          <Button onClick={handleClose} variant="secondary">
            保存せず閉じる
          </Button>
        }
        rightArea={
          <Button
            disabled={formState.isSubmitting}
            onClick={handleModalSubmit}
            variant="primary"
          >
            {createMode ? '作成' : '更新'}
          </Button>
        }
      />
    </Modalv2>
  );
};
