import React from 'react';
import { Box, RiStickyNoteLine, Text } from '@itandi/itandi-bb-ui';
import { Deal } from '~/models/Deal';
import { useDealVendorNotes } from '~/hooks/vendors/deals/useDealVendorNotes';
import Styles from '../DealCard.module.scss';
import { AccordionHtml } from '~/components/common/accordion_html/AccordionHtml';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { DealVendorNoteModal } from '~/components/common/deals/show/vendor_card/DealVendorNoteModal';
import { displayUpdateUserName } from '~/models/DealVendorNote';

type Props = {
  deal: Deal;
  handleMutateDeal: () => Promise<void>;
};

/**
 * 共有案件メモCard
 * @for vendor
 */
export const DealVendorNoteCard: React.FC<Props> = ({
  deal,
  handleMutateDeal
}: Props) => {
  const {
    data: dealVendorNote,
    isLoading,
    mutate
  } = useDealVendorNotes().show(deal.id, deal.dealVendorNoteId);
  const { data: dealVendorNoteTemplate } = useDealVendorNotes().template(
    deal.id
  );
  const { isOpen, handleClose, handleOpen } = useOpenHandler();
  const handleMutate = async (): Promise<void> => {
    if (dealVendorNote === null) {
      // 作成時はDealごとMutate
      await handleMutateDeal();
    } else {
      // 更新時はメモのみMutate
      mutate && (await mutate());
    }
  };
  const updateUserName = dealVendorNote
    ? displayUpdateUserName(dealVendorNote)
    : '';
  if (isLoading || dealVendorNoteTemplate == null) {
    return null;
  }

  return (
    <Box>
      <div className={Styles.DealCard}>
        <h3 className={Styles.Title}>
          <div>
            <div>
              <RiStickyNoteLine className={Styles.TitleIcon} />
              共有案件メモ（施工会社入力）
            </div>
            {dealVendorNote !== null && (
              <div className={Styles.UpdateUser}>
                <Text size="XS">最終更新者 : {updateUserName}</Text>
              </div>
            )}
          </div>
          <div className={Styles.EditButtonWrapper}>
            <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
            <DealVendorNoteModal
              deal={deal}
              dealVendorNote={dealVendorNote}
              dealVendorNoteTemplate={dealVendorNoteTemplate}
              handleClose={handleClose}
              handleMutate={handleMutate}
              isOpen={isOpen}
            />
          </div>
        </h3>
        <div className={Styles.Content}>
          {dealVendorNote === null ? (
            <span className={Styles.NotYet}>未入力</span>
          ) : (
            <>
              <AccordionHtml
                html={dealVendorNote.noteHtml}
                maxHeightForClosed={170}
              />
            </>
          )}
        </div>
      </div>
    </Box>
  );
};
