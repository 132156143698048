import React from 'react';
import { Box, RiStickyNoteLine, Text } from '@itandi/itandi-bb-ui';
import { Deal } from '~/models/Deal';
import { useDealVendorNotes } from '~/hooks/deals/useDealVendorNotes';
import Styles from '../DealCard.module.scss';
import { AccordionHtml } from '~/components/common/accordion_html/AccordionHtml';
import { displayUpdateUserName } from '~/models/DealVendorNote';

type Props = {
  deal: Deal;
};

/**
 * 共有案件メモCard
 * @for client
 */
export const DealVendorNoteCard: React.FC<Props> = ({ deal }: Props) => {
  const { data: dealVendorNote, isLoading } = useDealVendorNotes().show(
    deal.id,
    deal.dealVendorNoteId
  );
  const updateUserName = dealVendorNote
    ? displayUpdateUserName(dealVendorNote)
    : '';
  if (isLoading || dealVendorNote === null) {
    return null;
  }

  return (
    <Box>
      <div className={Styles.DealCard}>
        <h3 className={Styles.Title}>
          <div>
            <div>
              <RiStickyNoteLine className={Styles.TitleIcon} />
              共有案件メモ（施工会社入力）
            </div>
            <div className={Styles.UpdateUser}>
              <Text size="XS">最終更新者 : {updateUserName}</Text>
            </div>
          </div>
        </h3>
        <div className={Styles.Content}>
          <AccordionHtml
            html={dealVendorNote.noteHtml}
            maxHeightForClosed={170}
          />
        </div>
      </div>
    </Box>
  );
};
