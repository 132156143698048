import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, InputRadio, ItandiChip } from '@itandi/itandi-bb-ui';
import { RiUserLine } from 'react-icons/ri';
import Styles from './Block.module.scss';
import { DealUpdateParam } from '~/models/DealForm';

export const TenantBlock: React.FC = () => {
  const { register, watch, formState } = useFormContext<DealUpdateParam>();
  const { errors } = formState;
  const tenantStatus: 'resident' | 'leaved' = watch('tenantStatus');
  return (
    <div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormRowLabel}>
          <span className={Styles.BlockIconWrapper}>
            <RiUserLine className={Styles.BlockIcon} />
          </span>
          入居者情報
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>立会依頼</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.InputRadioGroup}>
            <InputRadio
              label="する"
              value="resident"
              {...register('tenantStatus')}
            />
            <InputRadio
              label="しない"
              value="leaved"
              {...register('tenantStatus')}
            />
          </div>
          {errors.enableAutoLock != null && (
            <div className={Styles.ErrorMessage}>
              {errors.enableAutoLock.message}
            </div>
          )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>入居者氏名</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label={tenantStatus !== 'resident' ? '任意' : '必須'} />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>氏名</div>
          <div>
            <div>
              <Input
                isInvalid={errors.tenantName != null}
                {...register('tenantName')}
              />
            </div>
            {errors.tenantName != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.tenantName.message}
              </div>
            )}
          </div>
          <div>カナ</div>
          <div>
            <div>
              <Input
                isInvalid={errors.tenantNameKana != null}
                {...register('tenantNameKana')}
              />
            </div>
            {errors.tenantNameKana != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.tenantNameKana.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>入居者電話番号</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label={tenantStatus !== 'resident' ? '任意' : '必須'} />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <div>
              <Input
                isInvalid={errors.tenantTel != null}
                {...register('tenantTel')}
              />
            </div>
            {errors.tenantTel != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.tenantTel.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          <span className={Styles.FormLabelText}>入居者メールアドレス</span>
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <div>
              <Input
                isInvalid={errors.tenantEmail != null}
                {...register('tenantEmail')}
              />
            </div>
            {errors.tenantEmail != null && (
              <div className={Styles.InvalidFeedback}>
                {errors.tenantEmail.message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
