import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './DealLayout.module.scss';
import { Deal } from '~/models/Deal';
import { DealLayoutHeaderClient } from '~/components/common/layouts/deals/DealLayoutHeaderClient';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { DealLayoutHeaderVendor } from './DealLayoutHeaderVendor';

type Props = {
  deal: Deal;
  children: ReactNode;
  breadcrumbItems: BreadcrumbItem[];
  noBackground?: boolean;
  isVendor?: boolean;
  useSimpleSpHeader?: boolean;
};

export const DealLayout: React.FC<Props> = ({
  deal,
  children,
  breadcrumbItems,
  noBackground = false,
  isVendor = false,
  useSimpleSpHeader = false
}: Props) => (
  <div>
    {isVendor ? (
      <DealLayoutHeaderVendor
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        useSimpleSpHeader={useSimpleSpHeader}
      />
    ) : (
      <DealLayoutHeaderClient
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        useSimpleSpHeader={useSimpleSpHeader}
      />
    )}
    <div
      className={clsx(
        Styles.Container,
        noBackground ? Styles.Transparent : Styles.White
      )}
    >
      {children}
    </div>
  </div>
);
