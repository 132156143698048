import React from 'react';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { StatusHandleButtons } from '~/components/common/statuses/StatusHandleButtons';
import { update as updateStatus } from '~/utils/api/vendors/DealStatus';
import { isSuccess } from '~/utils/api/api_handler';
import useLoader from '~/hooks/useLoader';
import { useDeals } from '~/hooks/vendors/useDeals';

type Props = {
  nextStatusId: number;
  prevStatusId?: number | null;
  forwardDisabled?: boolean;
  backwardDisabled?: boolean;
};

export const StatusHandleVendorButtons: React.FC<Props> = ({
  nextStatusId,
  prevStatusId = null,
  forwardDisabled = false,
  backwardDisabled = false
}: Props) => {
  const router = useRouter();
  const { Loader, showLoader, hideLoader } = useLoader();
  const { id: dealId = null } = router.query;
  const { data: deal, mutate: mutateDeal } = useDeals().show(
    dealId === null ? null : Number(dealId)
  );
  if (deal === null) {
    return null;
  }
  const handleUpdateStatus = async (statusId: number): Promise<void> => {
    showLoader();
    const result = await updateStatus(deal.id, statusId);
    if (isSuccess(result)) {
      mutateDeal && (await mutateDeal());
      hideLoader();
      toast.success('更新しました');
      return;
    }
    hideLoader();
    toast.error(`エラーが発生しました。${result.message}`);
  };
  const handleForwardStatus = async (): Promise<void> => {
    await handleUpdateStatus(nextStatusId);
  };
  const handleBackwardStatus = async (): Promise<void> => {
    if (prevStatusId === null) return;
    await handleUpdateStatus(prevStatusId);
  };
  return (
    <>
      <Loader />
      <StatusHandleButtons
        backwardDisabled={backwardDisabled}
        canBackward={prevStatusId !== null}
        forwardDisabled={forwardDisabled}
        handleBackwardStatus={handleBackwardStatus}
        handleForwardStatus={handleForwardStatus}
      />
    </>
  );
};
