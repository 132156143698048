import React from 'react';
import { RiFile2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import Styles from './BillingCreateNavigation.module.scss';
import { toThousandSeparator } from '~/utils/CurrencySeparator';
import { Button } from '~/components/common/parts/button/Button';
import { create as generateBillings } from '~/utils/api/deals/DealBillingGenerate';
import { isSuccess } from '~/utils/api/api_handler';
import { PATH as OWNER_BILLINGS_PATH } from '~/pages/deals/[id]/billings/edit';
import { PATH as RENTEE_BILLINGS_PATH } from '~/pages/deals/[id]/billing_rentees/edit';
import { DealEstimationContext } from '~/models/DealEstimate';
import { DealBillingContext } from '~/models/DealBilling';

type Props = {
  title: string;
  dealId: number;
  estimationContext: DealEstimationContext;
  ownerBillingContext: DealBillingContext;
  renteeBillingContext: DealBillingContext;
  mutateBillings: () => Promise<void>;
};

/**
 *
 * @param title
 * @param dealId
 * @param estimationContext
 * @param ownerBillingContext
 * @param renteeBillingContext
 * @constructor
 * @for client
 */
export const BillingCreateNavigation: React.FC<Props> = ({
  title,
  dealId,
  estimationContext,
  ownerBillingContext,
  renteeBillingContext,
  mutateBillings
}: Props) => {
  const router = useRouter();
  if (estimationContext.dealConstructions.length === 0) {
    return null;
  }
  if (
    ownerBillingContext.dealConstructions.length > 0 ||
    renteeBillingContext.dealConstructions.length > 0
  ) {
    const { sumOwnerAmountWithTax } = ownerBillingContext;
    const { sumRenteeAmountWithTax } = renteeBillingContext;
    return (
      <div className={Styles.NavigationBlock}>
        <div className={Styles.IconWrapper}>
          <RiFile2Line className={Styles.Icon} />
        </div>
        <div className={Styles.NavigationContentList}>
          <h4 className={Styles.Title}>{title}</h4>
          <div className={Styles.BillingRow}>
            <div className={Styles.BillingRowTitle}>
              オーナーへの請求書
              <div className={Styles.BillingRowSubText}>
                入力中のオーナーへの請求額：¥
                {toThousandSeparator(sumOwnerAmountWithTax)}（税込）
              </div>
            </div>
            <div className={Styles.DetailLinkBlock}>
              <Button
                className={Styles.DetailLinkButton}
                onClick={(): void => {
                  router.push(OWNER_BILLINGS_PATH(dealId)).then();
                }}
                size="lg"
                variant="normal"
              >
                請求書明細画面
              </Button>
            </div>
          </div>
          <div className={Styles.BillingRow}>
            <div className={Styles.BillingRowTitle}>
              入居者への請求書
              <div className={Styles.BillingRowSubText}>
                入力中の入居者への請求額：¥
                {toThousandSeparator(sumRenteeAmountWithTax)}（税込）
              </div>
            </div>
            <div className={Styles.DetailLinkBlock}>
              <Button
                className={Styles.DetailLinkButton}
                onClick={(): void => {
                  router.push(RENTEE_BILLINGS_PATH(dealId)).then();
                }}
                size="lg"
                variant="normal"
              >
                請求書明細画面
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onClickCreateBillings = async (): Promise<void> => {
    const result = await generateBillings(dealId, null);
    if (isSuccess(result)) {
      await mutateBillings();
      toast.success('請求を作成しました');
      return;
    }
    toast.error('エラーが発生しました');
  };
  return (
    <div className={Styles.SuggestBlock}>
      <div className={Styles.IconWrapper}>
        <RiFile2Line className={Styles.Icon} />
      </div>
      <div className={Styles.NavigationContent}>
        <div>
          <h4 className={Styles.Title}>{title}</h4>
        </div>
        <div className={Styles.DetailLinkBlock}>
          <Button onClick={onClickCreateBillings} size="lg" variant="normal">
            工事見積りから請求を作成する
          </Button>
        </div>
      </div>
    </div>
  );
};
