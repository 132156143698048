import React from 'react';
import Styles from './statuses.module.scss';
import { useDealEstimations } from '~/hooks/deals/useDealEstimations';
import { useDealInspectionReportImages } from '~/hooks/deals/useDealInspectionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as INSPECTION_REPORT_PATH } from '~/pages/deals/[id]/inspection_reports';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH as ESTIMATIONS_PATH } from '~/pages/deals/[id]/estimations';
import { PATH as ESTIMATIONS_EDIT_PATH } from '~/pages/deals/[id]/estimations/edit';
import { UploadFileNavigation } from '~/components/common/upload_files/UploadFileNavigation';
import { StatusHandleClientButtons } from '~/components/common/statuses/StatusHandleClientButtons';
import { IN_CONSTRUCTION, IN_ESTIMATION } from '~/models/Status';
import { DealUploadFile } from '~/models/DealUploadFile';
import { getFilePath as getClientFilePath } from '~/utils/api/deals/DealUploadedFile';
import { useDeals } from '~/hooks/useDeals';
import { matchDealType } from '~/models/Deal';
import {
  getOriginDealVendor,
  getOriginVendor
} from '~/models/DealVendorCompany';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const SendEstimate: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal } = useDeals().show(dealId);
  const { data: dealEstimationContext } = useDealEstimations().index(dealId);
  const reportsApi = useDealInspectionReportImages();
  const { data: reportImages } = reportsApi.index(Number(dealId));
  const allowPartiallyFinish = React.useMemo(() => {
    if (deal === null) return false;
    const originVendor = getOriginVendor(deal);
    return originVendor !== null && originVendor.allowPartiallyFinished;
  }, [deal]);
  if (deal === null || dealId === null) return null;
  const skipEstimation = getOriginDealVendor(deal)?.skipEstimation;
  const handleGetMemorandumFilePath = (file: DealUploadFile): string =>
    getClientFilePath(deal.id, file);
  return (
    <div>
      <h3 className={Styles.Title}>
        {`${
          matchDealType(deal, 'restoration') ? '退去' : ''
        }立会が完了しました`}
      </h3>
      {reportImages !== null && (
        <ReportImageNavigation
          detailPageButtonLabel="画像確認画面"
          detailPagePath={INSPECTION_REPORT_PATH(dealId)}
          reportImages={reportImages}
          title={`${
            matchDealType(deal, 'repair') ? '現地調査の' : '立会報告'
          }画像を確認してください`}
        />
      )}
      {dealEstimationContext !== null && (
        <ConstructionNavigation
          dealConstructionContext={dealEstimationContext}
          detailPagePath={
            skipEstimation
              ? ESTIMATIONS_EDIT_PATH(dealId)
              : ESTIMATIONS_PATH(dealId)
          }
          title={
            skipEstimation
              ? '見積りを入力してください'
              : '見積りを確認してください'
          }
        />
      )}
      {deal.dealMemorandumFiles.length > 0 && (
        <UploadFileNavigation
          deal={deal}
          files={deal.dealMemorandumFiles}
          fileType="賃借人同意書"
          handleGetFilePath={handleGetMemorandumFilePath}
          title="賃借人同意書を確認してください"
        />
      )}
      <StatusHandleClientButtons
        alertText="確定ボタンを押すと工事の手配へ移ります。"
        allowPartiallyFinish={allowPartiallyFinish}
        nextStatusId={IN_CONSTRUCTION}
        prevStatusId={skipEstimation ? null : IN_ESTIMATION}
      />
    </div>
  );
};
