import { number, object, string } from 'yup';
import { ConstructionItem } from '~/models/ConstructionItem';
import { DealEstimation, DealEstimationContext } from '~/models/DealEstimate';
import { DealBilling, DealBillingContext } from '~/models/DealBilling';
import { SettlementItem } from './SettlementItem';

export type DealConstructionBase = {
  id: number;
  seq: number;
  constructionItem: ConstructionItem | null;
  settlementItem: SettlementItem | null;
  otherName: string;
  point: string;
  unit: number;
  unitAmount: number;
  totalAmount: number;
  taxType: 'standard' | 'nontaxable';
  transactionType: 'billing' | 'refund';
  renteeChargePer: number;
  renteeChargeWithPercentage: string;
  ownerChargePer: number;
  ownerChargeWithPercentage: string;
  companyChargePer: number;
  companyChargeWithPercentage: string;
  renteeAmount: number;
  ownerAmount: number;
  companyAmount: number;
  note: string;
  enabled: boolean;
};

export type DealConstruction = DealEstimation | DealBilling;

export type DealConstructionEdit = Omit<
  DealConstruction,
  | 'id'
  | 'seq'
  | 'settlementItem'
  | 'unit'
  | 'unitAmount'
  | 'taxType'
  | 'transactionType'
  | 'renteeChargePer'
  | 'renteeChargeWithPercentage'
  | 'ownerChargeWithPercentage'
  | 'companyChargeWithPercentage'
  | 'type'
> & {
  unit: number | '';
  unitAmount: number | '';
  renteeChargePer: number | '';
};

export type DealConstructionContextBase = {
  totalAmount: number;
  totalAmountWithTax: number;
  constructionTotalAmount: number;
  constructionTotalAmountWithTax: number;
  dealConstructions: DealConstruction[];
};
export type DealConstructionContext =
  | DealEstimationContext
  | DealBillingContext;

export const isDealBillingContext = (
  context: DealConstructionContext
): context is DealBillingContext => 'dealSettlements' in context;

export const validationSchemaConstruction = object().shape({
  constructionItem: object().nullable().required('施工項目を設定してください'),
  point: string().nullable(),
  unit: number()
    .min(1, '1以上で設定してください')
    .required('入力してください')
    .transform((value: string) => (Number.isNaN(value) ? Number('') : value)),
  unitAmount: number()
    .transform((value: string) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('入力してください'),
  renteeChargePer: string()
    .required('入力してください')
    .test(
      'min',
      '0以上で設定して下さい',
      function testValidation(_value: unknown): boolean {
        const { renteeChargePer } = this.parent;
        return renteeChargePer >= 0;
      }
    )
    .test(
      'hundredPer',
      '合計が100になるように入力してください',
      function testValidation(_value: unknown): boolean {
        const { ownerChargePer, renteeChargePer, companyChargePer } =
          this.parent;
        const sum =
          Number(ownerChargePer) +
          Number(renteeChargePer) +
          Number(companyChargePer);
        return sum === 100;
      }
    ),
  ownerChargePer: string()
    .required('入力してください')
    .test(
      'min',
      '0以上で設定して下さい',
      function testValidation(_value: unknown): boolean {
        const { ownerChargePer } = this.parent;
        return ownerChargePer >= 0;
      }
    ),
  companyChargePer: string()
    .required('入力してください')
    .test(
      'min',
      '0以上で設定して下さい',
      function testValidation(_value: unknown): boolean {
        const { companyChargePer } = this.parent;
        return companyChargePer >= 0;
      }
    )
});

export const buildDefaultConstruction = (): DealConstructionEdit => ({
  constructionItem: null,
  otherName: '',
  point: '',
  unit: 1,
  unitAmount: 0,
  totalAmount: 0,
  renteeChargePer: 0,
  ownerChargePer: 100,
  companyChargePer: 0,
  renteeAmount: 0,
  ownerAmount: 0,
  companyAmount: 0,
  note: '',
  enabled: true
});
