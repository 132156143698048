import React from 'react';
import { RiMapPinLine } from 'react-icons/ri';
import cx from 'clsx';
import Styles from './DealLayoutHeader.module.scss';
import { Deal } from '~/models/Deal';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { DealTypeLabel } from '~/components/common/parts/label/DealTypeLabel';
import { MEDIA_QUERY, useMediaQuery } from '~/hooks/useMediaQuery';
import { SimpleHeaderVendorSP } from './simple_header/SimpleHeaderVendorSP';

type Props = {
  deal: Deal;
  breadcrumbItems: BreadcrumbItem[];
  useSimpleSpHeader: boolean;
};

export const DealLayoutHeaderVendor: React.FC<Props> = ({
  deal,
  breadcrumbItems,
  useSimpleSpHeader
}: Props) => {
  const { match: isSp, isLoaded: windowLoaded } = useMediaQuery(MEDIA_QUERY.sp);
  if (!windowLoaded) {
    return null;
  }
  return (
    <>
      {useSimpleSpHeader && isSp ? (
        <SimpleHeaderVendorSP breadcrumbItems={breadcrumbItems} deal={deal} />
      ) : (
        <div className={Styles.DealHeader} id="deal_header">
          <div className={Styles.HeaderLeftContent}>
            <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            <div className={Styles.HeaderElements}>
              <div className={Styles.AlignCenter}>
                <DealTypeLabel
                  dealSubtype={deal.dealSubtype}
                  dealType={deal.dealType}
                />
              </div>
              <div>
                <h1 className={Styles.RoomName}>
                  {deal.name} {deal.roomNumber}
                </h1>
              </div>
              <div className={cx(Styles.AlignCenter, Styles.DealAddress)}>
                <RiMapPinLine className={Styles.Icon} />
                {deal.address}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
