import React, { ReactNode } from 'react';
import {
  InputCheckBox,
  InputSelect,
  InputRadio,
  Input,
  InputText,
  ItandiChip
} from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  NotificationEdit,
  ONLY_IMMEDIATE_TRIGGER_TYPE
} from '~/models/Notification';
import Styles from './NotificationModal.module.scss';
import { Status, statusLabel } from '~/models/Status';
import { Subtype } from '~/models/Subtype';
import { DealStateRadio } from './ModalForm/DealStateRadio';
import { NotificationMailsCheckBox } from '~/components/common/notifications/settings/ModalForm/NotificationMailsCheckBox';
import { TriggerTypeSelect } from '~/components/common/notifications/settings/ModalForm/TriggerTypeSelect';
import { ReceiveTypeRadio } from '~/components/common/notifications/settings/ModalForm/RecieveTypeRadio';
import { LineUserAccount } from '~/models/LineUserAccount';
import { NotificationLineSettingsCheckBox } from '~/components/common/notifications/settings/ModalForm/NotificationLineSettingsCheckBox';

type Props = DeepReadonly<{
  type: 'CompanyNotification' | 'VendorNotification';
  statuses: Status[];
  subtypes: Subtype[] | null;
  lineUserAccounts: LineUserAccount[] | null;
  useLineNotification: boolean;
}>;

export const NotificationModalForm: React.FC<Props> = ({
  type,
  statuses,
  subtypes,
  lineUserAccounts,
  useLineNotification
}: Props) => {
  const { register, watch, formState, setValue } =
    useFormContext<NotificationEdit>();
  const { errors } = formState;

  const notifyType = watch('notifyType');
  const triggerType = watch('triggerType');
  const notifyReservationDays = watch('notifyReservationDays');
  const enableNotifyTypeReservation = !!(
    triggerType && !ONLY_IMMEDIATE_TRIGGER_TYPE.includes(triggerType)
  );
  const reservationDescription = React.useMemo(() => {
    if (notifyType !== 'reservation' || notifyReservationDays === null) {
      return null;
    }
    if (triggerType === 'status') {
      if (notifyReservationDays <= 0) {
        // ステータスのときはマイナスを入れさせない
        return null;
      }
      return `${notifyReservationDays}日間同じステータスだったときに通知されます`;
    }
    if (triggerType === 'specific_date') {
      if (notifyReservationDays === 0) {
        return '特定日付の当日に通知されます';
      }
      if (notifyReservationDays < 0) {
        return `特定日付の${notifyReservationDays * -1}日前に通知されます`;
      }
      return `特定日付の${notifyReservationDays}日後に通知されます`;
    }
    return null;
  }, [notifyType, triggerType, notifyReservationDays]);

  return (
    <div className={Styles.Forms}>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          案件種別
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <InputSelect
              isInvalid={errors.dealType != null}
              {...register('dealType')}
            >
              <option value="all">すべて</option>
              <option value="restoration">原状回復</option>
              <option value="repair">修繕</option>
              <option value="other">その他</option>
            </InputSelect>
          </div>
          {errors.dealType != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.dealType.message}
            </div>
          )}
        </div>
      </div>
      {watch('dealType') === 'other' && type === 'CompanyNotification' && (
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabelCol}>
            案件のサブタイプ
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormSettingCol}>
            <div>
              <InputSelect
                isInvalid={errors.dealSubtypeId != null}
                {...register('dealSubtypeId', {
                  setValueAs: (value) =>
                    value == null || value === '' ? null : Number(value)
                })}
              >
                <option value="">選択なし</option>
                {subtypes &&
                  subtypes.map(
                    (subtype: Subtype): ReactNode => (
                      <option key={subtype.id} value={subtype.id}>
                        {subtype.name}
                      </option>
                    )
                  )}
              </InputSelect>
              {errors.dealSubtypeId != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.dealSubtypeId.message}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          通知トリガー
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <TriggerTypeSelect type={type} />
        </div>
      </div>
      {watch('triggerType') === 'status' && (
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabelCol}>
            トリガーステータス
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormSettingCol}>
            <div>
              <InputSelect
                isInvalid={errors.triggerStatusId != null}
                {...register('triggerStatusId', {
                  setValueAs: (value) =>
                    value == null || value === '' ? null : Number(value)
                })}
              >
                <option value="">選択なし</option>
                {statuses.map(
                  (status: Status): ReactNode => (
                    <option key={status.id} value={status.id}>
                      {statusLabel(status)}
                    </option>
                  )
                )}
              </InputSelect>
              {errors.triggerStatusId != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.triggerStatusId.message}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {watch('triggerType') === 'specific_date' && (
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabelCol}>
            特定日付
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormSettingCol}>
            <div>
              <InputSelect
                isInvalid={errors.triggerDateType != null}
                {...register('triggerDateType')}
              >
                <option value="">選択なし</option>
                <option value="inspection_date">立会日</option>
                <option value="construction_start_date">工事開始日</option>
                <option value="construction_end_date">工事終了日</option>
              </InputSelect>
              {errors.triggerDateType != null && (
                <div className={Styles.InvalidFeedback}>
                  {errors.triggerDateType.message}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          案件状況
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.SubLabel}>
            下記で設定した条件がすべて当てはまる場合に通知が送信されます
            <br />
            通知トリガーが案件取り下げ時の場合を除き、
            <br />
            案件ステータスが取り下げの場合には通知されません。
          </div>
          <DealStateRadio formName="dealEstimationState" />
          <DealStateRadio formName="dealInspectionReportState" />
          <DealStateRadio formName="dealCompletionReportState" />
          <DealStateRadio formName="dealConstructionScheduleState" />
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          通知タイプ
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.RadioGroup}>
            <InputRadio
              isInvalid={errors.notifyType != null}
              label="即時通知"
              value="immediate"
              {...register('notifyType')}
            />
            {enableNotifyTypeReservation && (
              <InputRadio
                isInvalid={errors.notifyType != null}
                label="通知予約"
                value="reservation"
                {...register('notifyType')}
              />
            )}
          </div>
          {watch('notifyType') === 'reservation' &&
            enableNotifyTypeReservation && (
              <div className={Styles.InnerSettingBlock}>
                <div className={Styles.SubLabel}>通知予約日数</div>
                <div className={Styles.InputWithUnitBlock}>
                  <Input
                    className={Styles.DaysInput}
                    isInvalid={errors.notifyReservationDays != null}
                    type="number"
                    {...register('notifyReservationDays', {
                      setValueAs: (value) =>
                        value == null || value === '' ? null : Number(value)
                    })}
                  />
                  <span>日</span>
                </div>
                {reservationDescription !== null && (
                  <div className={Styles.Description}>
                    {reservationDescription}
                  </div>
                )}
                {errors.notifyReservationDays != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.notifyReservationDays.message}
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          通知方法
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="必須" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div className={Styles.RadioGroup}>
            <InputRadio
              isInvalid={errors.sendType != null}
              label="メール"
              value="mail"
              {...register('sendType')}
            />
            <InputRadio
              isInvalid={errors.sendType != null}
              label="Slack"
              value="slack"
              {...register('sendType')}
            />
            {useLineNotification && (
              <InputRadio
                isInvalid={errors.sendType != null}
                label="LINE"
                value="line"
                {...register('sendType')}
              />
            )}
          </div>
          {errors.sendType != null && (
            <div className={Styles.InvalidFeedback}>
              {errors.sendType.message}
            </div>
          )}
          {watch('sendType') === 'mail' && (
            <div className={Styles.InnerSettingBlock}>
              <div className={Styles.SubLabel}>通知先</div>
              <NotificationMailsCheckBox />
            </div>
          )}
          {watch('sendType') === 'slack' && (
            <div className={Styles.SlackForms}>
              <div>
                <div className={Styles.SubLabel}>Slack Webhook URL</div>
                <Input
                  isInvalid={errors.slackWebhookUrl != null}
                  placeholder="https://hooks.slack.com/services/XXXXXXXXX/XXXXXXXXX/XXXXXXXXXXXXXXXXXXXXXXXX"
                  {...register('slackWebhookUrl')}
                />
                {errors.slackWebhookUrl != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.slackWebhookUrl.message}
                  </div>
                )}
              </div>
              <div>
                <div className={Styles.SubLabel}>通知ユーザー名</div>
                <Input
                  isInvalid={errors.slackUserName != null}
                  placeholder="内装工事くん"
                  {...register('slackUserName')}
                />
                {errors.slackUserName != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.slackUserName.message}
                  </div>
                )}
              </div>
              <div>
                <div className={Styles.SubLabel}>通知アイコン</div>
                <Input
                  isInvalid={errors.slackIconEmoji != null}
                  placeholder="construction_worker"
                  {...register('slackIconEmoji')}
                />
                {errors.slackIconEmoji != null && (
                  <div className={Styles.InvalidFeedback}>
                    {errors.slackIconEmoji.message}
                  </div>
                )}
              </div>
            </div>
          )}
          {watch('sendType') === 'line' && (
            <div className={Styles.InnerSettingBlock}>
              <div className={Styles.SubLabel}>通知先</div>
              <NotificationLineSettingsCheckBox
                lineUserAccounts={lineUserAccounts}
              />
            </div>
          )}
        </div>
      </div>
      {type === 'VendorNotification' && triggerType && (
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabelCol}>
            受信タイプ
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormSettingCol}>
            <ReceiveTypeRadio />
          </div>
        </div>
      )}
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          再通知
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <InputCheckBox
            checked={watch('resend')}
            labelText="通知済みでも再入力により再通知する"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setValue('resend', e.target.checked);
            }}
          />
        </div>
      </div>
      <div className={Styles.FormRow}>
        <div className={Styles.FormLabelCol}>
          通知内容
          <span className={Styles.RequiredLabel}>
            <ItandiChip label="任意" />
          </span>
        </div>
        <div className={Styles.FormSettingCol}>
          <div>
            <div className={Styles.SubLabel}>
              {watch('sendType') === 'mail' ? '通知メール件名' : '通知タイトル'}
            </div>
            <div>
              <Input
                placeholder="例）工事完了のお知らせ"
                {...register('title')}
              />
            </div>
          </div>
          <div className={Styles.InnerSettingBlock}>
            <div className={Styles.SubLabel}>
              {watch('sendType') === 'mail' ? '通知メール本文' : '通知本文'}
            </div>
            <div>
              <InputText
                placeholder="例）工事が完了しました。確認をお願いします"
                rows={3}
                {...register('content')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
