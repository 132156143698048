import React from 'react';
import { Button } from '@itandi/itandi-bb-ui';
import { RiMapPinLine } from 'react-icons/ri';
import cx from 'clsx';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import Styles from './DealLayoutHeader.module.scss';
import { Deal } from '~/models/Deal';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { DealTypeLabel } from '~/components/common/parts/label/DealTypeLabel';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';
import { create } from '~/utils/api/deals/DealDuplication';
import { isSuccess } from '~/utils/api/api_handler';
import { PATH } from '~/pages/deals/[id]';
import { ClientRelateDealsButton } from '~/components/common/layouts/deals/relate_deals/ClientRelateDealsButton';
import { DealRepairCardModal } from '../../deals/show/repair_card/DealRepairCardModal';
import { MEDIA_QUERY, useMediaQuery } from '~/hooks/useMediaQuery';
import { SimpleHeaderClientSP } from '~/components/common/layouts/deals/simple_header/SimpleHeaderClientSP';

type Props = {
  deal: Deal;
  breadcrumbItems: BreadcrumbItem[];
  useSimpleSpHeader: boolean;
};

export const DealLayoutHeaderClient: React.FC<Props> = ({
  deal,
  breadcrumbItems,
  useSimpleSpHeader
}: Props) => {
  const router = useRouter();
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const {
    isOpen: openRepairModal,
    handleOpen: handleOpenRepairModal,
    handleClose: handleCloseRepairModal
  } = useOpenHandler();
  const { match: isSp } = useMediaQuery(MEDIA_QUERY.sp);
  const handleAcceptDuplicate = async (): Promise<void> => {
    const result = await create(deal.id);
    if (isSuccess(result)) {
      handleClose();
      const newId = result.data?.id ?? null;
      if (newId !== null) {
        await router.push(PATH(newId));
        toast.success('関連案件として追加しました');
        return;
      }
    }
    toast.error('エラーが発生しました');
  };
  return (
    <>
      {useSimpleSpHeader && isSp ? (
        <SimpleHeaderClientSP breadcrumbItems={breadcrumbItems} deal={deal} />
      ) : (
        <div className={Styles.DealHeader} id="deal_header">
          <div className={Styles.HeaderLeftContent}>
            <Breadcrumbs breadcrumbItems={breadcrumbItems} />
            <div className={Styles.HeaderElements}>
              <div className={Styles.AlignCenter}>
                <DealTypeLabel
                  dealSubtype={deal.dealSubtype}
                  dealType={deal.dealType}
                />
              </div>
              <div>
                <h1 className={Styles.RoomName}>
                  {deal.name} {deal.roomNumber}
                </h1>
              </div>
              <div className={cx(Styles.AlignCenter, Styles.DealAddress)}>
                <RiMapPinLine className={Styles.Icon} />
                {deal.address}
              </div>
            </div>
          </div>
          <div className={Styles.HeaderRightContent}>
            <ClientRelateDealsButton />
            <Button
              disabled={deal.dealType === 'other' && deal.dealSubtype === null}
              onClick={
                deal.dealType === 'repair' ? handleOpenRepairModal : handleOpen
              }
            >
              関連する案件追加
            </Button>
            <DealRepairCardModal
              deal={deal}
              duplicate
              handleClose={handleCloseRepairModal}
              isOpen={openRepairModal}
            />
            <ConfirmModal
              confirmType="save"
              handleAccept={handleAcceptDuplicate}
              handleCancel={handleClose}
              isOpen={isOpen}
            >
              関連する案件では、それぞれの物件情報と入居者情報が共有されます。
              <br />
              異なる区画や同時期ではない案件の追加は、案件一覧からお願いします。
              <br />
              関連する案件の追加をしてもよろしいですか？
            </ConfirmModal>
          </div>
        </div>
      )}
    </>
  );
};
