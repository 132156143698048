import React from 'react';
import Styles from './statuses.module.scss';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { IN_ESTIMATION } from '~/models/Status';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { getOriginDealVendor } from '~/models/DealVendorCompany';

export const NowAccepting: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  if (deal === null) return null;
  const skipEstimation = getOriginDealVendor(deal)?.skipEstimation;

  return (
    <div>
      <h3 className={Styles.Title}>案件が登録されました</h3>
      {skipEstimation ? (
        <p>
          管理会社によって見積作成が行われる案件です。
          <br />
          管理会社がステータスを進めるまでお待ち下さい。
          <br />
          管理会社がステータスを進めるまで、管理会社にて依頼先の変更が可能です。
        </p>
      ) : (
        <>
          <p>
            見積作成中ステータスに変更するまで、管理会社にて依頼先の変更が可能です。
          </p>
          <StatusHandleVendorButtons
            backwardDisabled
            nextStatusId={IN_ESTIMATION}
          />
        </>
      )}
    </div>
  );
};
