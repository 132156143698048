import React from 'react';
import { toast } from 'react-toastify';
import { Alert } from '@itandi/itandi-bb-ui';
import Styles from './Page.module.scss';
import { useDeals } from '~/hooks/useDeals';
import { useConstructionItems } from '~/hooks/useConstructionItems';
import Loading from '~/components/common/parts/loading/Loading';
import { useDealBillings } from '~/hooks/deals/useDealBillings';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PATH } from '~/pages/deals/[id]';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { ConstructionTop } from '~/components/common/constructions/ConstructionTop';
import { ConstructionList } from '~/components/common/constructions/list/ConstructionList';
import {
  DealConstruction,
  DealConstructionEdit
} from '~/models/DealConstruction';
import { DealSettlement, DealSettlementEdit } from '~/models/DealSettlement';
import { getDownloadPath } from '~/utils/api/deals/DealBillingFile';
import { NskResponse } from '~/models/Api';
import {
  create as postDealBilling,
  destroy as destroyBilling,
  update as updateDealBilling,
  updateSeq
} from '~/utils/api/deals/DealBilling';
import { ChangeAmountModal } from '~/components/common/constructions/change_amount/ChangeAmountModal';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { InvoiceItemAddForm } from '~/components/common/constructions/adding/InvoiceItemAddForm';
import { SettlementList } from '~/components/common/settlements/list/SettlementList';
import { useUsingApplications } from '~/hooks/useUsingApplications';
import { create as generateBillings } from '~/utils/api/deals/DealBillingGenerate';
import { isSuccess } from '~/utils/api/api_handler';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { ConfirmModal } from '~/components/common/parts/modal/ConfirmModal';

export const Page: React.FC = () => {
  const [showChangeAmountModal, setShowChangeAmountModal] =
    React.useState<boolean>(false);
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const id = useIdQueryHandler();
  const { data: deal } = useDeals().show(id);
  const { data: constructionItems } = useConstructionItems().index();
  const { useSK } = useUsingApplications();
  const { data: billingContext, mutate: mutateBillings } =
    useDealBillings().index(id, 'rentee');
  if (deal === null || billingContext === null || constructionItems === null) {
    return <Loading isLoading />;
  }
  const pageTitle = useSK
    ? '入居者への請求書作成'
    : '入居者への請求書・精算書作成';
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: PATH(deal?.id), caption: '案件詳細', active: false },
    { path: '', caption: pageTitle, active: true }
  ];
  const handleClickPreview = (): void => {
    if (id === null) return;
    const path = getDownloadPath(id, 'deal_billing_rentee');
    window.open(path);
  };
  const handleMutateConstructions = async (): Promise<void> => {
    mutateBillings != null && (await mutateBillings());
  };
  const handleCreate = async (
    submitValue: DealConstructionEdit | DealSettlementEdit
  ): Promise<NskResponse<void>> =>
    postDealBilling(submitValue, deal.id, 'rentee');
  const handleUpdate = async (
    submitValue: DealConstructionEdit | DealSettlementEdit,
    estimationId: number
  ): Promise<NskResponse<void>> =>
    updateDealBilling(submitValue, estimationId, deal.id);
  const handleDestroy = async (
    deleteBilling: DealConstruction | DealSettlement
  ): Promise<NskResponse<void>> => {
    if (deleteBilling.type !== 'DealBilling') {
      throw new Error(`Illegal construction type: ${deleteBilling.type}`);
    }
    if (deleteBilling.billingType !== 'rentee') {
      throw new Error(`Illegal billing type: ${deleteBilling.billingType}`);
    }
    return destroyBilling(deleteBilling, deal.id);
  };
  const handleUpdateSeq = async (
    newBillings: DealConstruction[] | DealSettlement[]
  ): Promise<void> => {
    await updateSeq(newBillings, deal.id);
  };
  const handleClickGenerateBillings = async (): Promise<void> => {
    const result = await generateBillings(deal.id, 'rentee');
    if (isSuccess(result)) {
      mutateBillings && (await mutateBillings());
      toast.success('請求を再作成しました');
      handleClose();
      return;
    }
    toast.error('エラーが発生しました');
    handleClose();
  };

  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
      >
        <h2 className={Styles.EstimateTitle}>{pageTitle}</h2>
        <ConstructionTop
          billingTarget="rentee"
          constructionContext={billingContext}
          handleClickChangeAmount={(): void => setShowChangeAmountModal(true)}
          handleClickGenerateBillings={handleOpen}
          handleClickPreview={handleClickPreview}
          readonly={false}
          useSK={useSK}
        />
        {showChangeAmountModal && (
          <ChangeAmountModal
            billingContext={billingContext}
            billingType="rentee"
            handleClose={(): void => setShowChangeAmountModal(false)}
          />
        )}
        {isOpen && (
          <ConfirmModal
            confirmType="save"
            handleAccept={handleClickGenerateBillings}
            handleCancel={handleClose}
            isOpen={isOpen}
          >
            工事見積りから請求を再作成しますか？
            <Alert marginTop={8}>
              現在登録されている入居者への請求情報は削除されます
            </Alert>
          </ConfirmModal>
        )}
        <div className={Styles.Container}>
          <div className={Styles.EstimateAddForm}>
            <InvoiceItemAddForm
              billingType="rentee"
              handleCreate={handleCreate}
              mutateConstructions={handleMutateConstructions}
            />
          </div>
          <div className={Styles.EstimateList}>
            {billingContext.dealSettlements.length > 0 && (
              <SettlementList
                constructionContext={billingContext}
                handleDestroy={handleDestroy}
                handleMutateSettlements={handleMutateConstructions}
                handleUpdate={handleUpdate}
                handleUpdateSeq={handleUpdateSeq}
                target="rentee"
              />
            )}
            <ConstructionList
              companyType="client"
              constructionContext={billingContext}
              constructionItems={constructionItems}
              docType="billing"
              handleDestroy={handleDestroy}
              handleMutateConstructions={handleMutateConstructions}
              handleUpdate={handleUpdate}
              handleUpdateSeq={handleUpdateSeq}
              readonly={false}
              target="rentee"
            />
          </div>
        </div>
      </DealLayout>
    </DefaultV2>
  );
};
