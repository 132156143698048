import React, { ReactNode } from 'react';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult
} from 'react-beautiful-dnd';
import {
  Button,
  Flex,
  BulkActionsDropdown,
  ConfirmModal,
  RiFilterOffLine
} from '@itandi/itandi-bb-ui';
import {
  RiCameraFill,
  RiCameraOffFill,
  RiChat4Fill,
  RiChatOffFill,
  RiCheckLine,
  RiDeleteBin6Fill,
  RiDownload2Line,
  RiEyeFill,
  RiEyeOffFill,
  RiFolderZipFill,
  RiFolderZipLine,
  RiHammerFill,
  RiIndeterminateCircleFill
} from 'react-icons/ri';
import Styles from './ReportImagesAreaSp.module.scss';
import {
  DealReportBulkUpdateParams,
  DealReportImage,
  ReportImageBulkAction
} from '~/models/DealReportImage';
import { NskResponse } from '~/models/Api';
import { ReportImagesAreaSpRow } from '~/components/common/reports/ReportImagesAreaSpRow';
import { useOpenHandler } from '~/hooks/useOpenHandler';

type Props = {
  reportImages: DealReportImage[];
  handleDownloadImage: (reportImage: DealReportImage) => void;
  handleMutateReportImages: () => Promise<void>;
  handleUpdateSeq?:
    | ((newOrderImages: DealReportImage[]) => Promise<void>)
    | null;
  handleUpdate: (submitValue: DealReportImage) => Promise<NskResponse>;
  handleDestroy?:
    | ((reportImage: DealReportImage) => Promise<NskResponse>)
    | null;
  handleDownloadPdf: () => void;
  handleDownloadZip?: () => void;
  companyType: 'management' | 'own_management' | 'vendor';
  onChangeSelectedImage: (reportImageId: number) => void;
  selectedImageIds: ReadonlyArray<number>;
  handleSelectedDownloadZip?: () => void;
  handleBulkUpdate: (submitValue: DealReportBulkUpdateParams) => Promise<void>;
  handleBulkDelete: (() => void) | null;
  handleResetSelectedIds: () => void;
  handleSelectAllImages: () => void;
};

export const ReportImagesAreaSp: React.FC<Props> = ({
  reportImages,
  handleDownloadImage,
  handleMutateReportImages,
  handleUpdateSeq = null,
  handleUpdate,
  handleDestroy = null,
  handleDownloadPdf,
  handleDownloadZip,
  companyType,
  onChangeSelectedImage,
  selectedImageIds,
  handleSelectedDownloadZip,
  handleBulkUpdate,
  handleBulkDelete,
  handleResetSelectedIds,
  handleSelectAllImages
}: Props) => {
  // 並び替えのためのstate
  const [orderImages, setOrderImages] = React.useState<DealReportImage[]>([]);
  const isVendor = companyType === 'vendor';
  const {
    isOpen: isOpenBulkDelete,
    handleOpen: handleOpenBulkDelete,
    handleClose: handleCloseBulkDelete
  } = useOpenHandler();
  React.useEffect(() => {
    setOrderImages(reportImages);
  }, [reportImages]);
  // 報告書用通し番号
  let tmpSeqNo = 0;
  const onDragEnd = (result: DropResult): void => {
    tmpSeqNo = 0;
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const moveImage =
      orderImages.find((e: DealReportImage) => String(e.id) === draggableId) ??
      null;
    const newOrderItems: DealReportImage[] = [];
    const wkOrderImages = [...orderImages];
    wkOrderImages.splice(source.index, 1);
    if (moveImage !== null) {
      wkOrderImages.splice(destination.index, 0, moveImage);
    }
    wkOrderImages.forEach((r: DealReportImage, i: number) => {
      newOrderItems.push({ ...r, seq: i + 1 });
    });
    setOrderImages(newOrderItems);
    handleUpdateSeq !== null && handleUpdateSeq(newOrderItems).then();
  };
  const renderRow = (
    reportImage: DealReportImage,
    index: number
  ): ReactNode => {
    const displaySeqNo = ((): number | null => {
      if (isVendor) {
        return index + 1;
      }
      if (reportImage.outputPdfImage) {
        tmpSeqNo += 1;
        return tmpSeqNo;
      }
      return null;
    })();
    return (
      <ReportImagesAreaSpRow
        key={reportImage.id}
        companyType={companyType}
        displaySeqNo={displaySeqNo}
        handleDestroy={handleDestroy}
        handleDownloadImage={handleDownloadImage}
        handleMutateReportImages={handleMutateReportImages}
        handleUpdate={handleUpdate}
        handleUpdateSeq={handleUpdateSeq}
        index={index}
        onChangeSelectedImage={onChangeSelectedImage}
        reportImage={reportImage}
        selectedImageIds={selectedImageIds}
      />
    );
  };
  const bulkActionList = (): ReportImageBulkAction[] => {
    const actions: ReportImageBulkAction[] = [];
    if (handleSelectedDownloadZip) {
      actions.push({
        children: '画像をダウンロード',
        icon: <RiFolderZipFill />,
        onClick: handleSelectedDownloadZip
      });
    }
    if (isVendor || companyType === 'own_management') {
      actions.push({
        children: '施工推奨にする',
        icon: <RiHammerFill />,
        onClick: () => handleBulkUpdate({ construction: 'targeted' })
      });
      actions.push({
        children: '施工推奨から外す',
        icon: <RiIndeterminateCircleFill />,
        onClick: () => handleBulkUpdate({ construction: 'unspecified' })
      });
    }
    if (isVendor) {
      actions.push({
        children: '写真を共有する',
        icon: <RiEyeOffFill />,
        onClick: () => handleBulkUpdate({ shared: true })
      });
      actions.push({
        children: '写真を共有しない',
        icon: <RiEyeFill />,
        onClick: () => handleBulkUpdate({ shared: false })
      });
    } else {
      actions.push({
        children: '写真を報告書に含める',
        icon: <RiCameraFill />,
        onClick: () => handleBulkUpdate({ outputPdfImage: true })
      });
      actions.push({
        children: '写真を報告書に含めない',
        icon: <RiCameraOffFill />,
        onClick: () => handleBulkUpdate({ outputPdfImage: false })
      });
      actions.push({
        children: '備考を報告書に含める',
        icon: <RiChat4Fill />,
        onClick: () => handleBulkUpdate({ outputPdfNote: true })
      });
      actions.push({
        children: '備考を報告書に含めない',
        icon: <RiChatOffFill />,
        onClick: () => handleBulkUpdate({ outputPdfNote: false })
      });
    }
    if (handleBulkDelete) {
      actions.push({
        children: '画像を削除',
        danger: true,
        icon: <RiDeleteBin6Fill />,
        onClick: handleOpenBulkDelete
      });
    }
    return actions;
  };
  return (
    <div className={Styles.ReportImagesArea}>
      <Flex direction="column" gap={8} padding={16}>
        <Button
          disabled={orderImages.length <= 0}
          endIcon={<RiDownload2Line />}
          onClick={handleDownloadPdf}
          variant="secondary"
        >
          報告書ダウンロード
        </Button>
        {handleDownloadZip && (
          <Button
            disabled={orderImages.length <= 0}
            endIcon={<RiFolderZipLine />}
            onClick={handleDownloadZip}
            variant="secondary"
          >
            画像一括ダウンロード
          </Button>
        )}
        <Button
          disabled={reportImages.length === 0}
          onClick={handleSelectAllImages}
          startIcon={<RiCheckLine />}
          variant="secondary"
        >
          全てを選択
        </Button>
        <Button
          disabled={selectedImageIds.length === 0}
          onClick={handleResetSelectedIds}
          startIcon={<RiFilterOffLine />}
          variant="secondary"
        >
          選択をクリア
        </Button>
      </Flex>
      <BulkActionsDropdown
        dropdownLabel="まとめて操作"
        dropdownMenuItems={bulkActionList()}
        dropdownPlacement="top"
        selectedCount={selectedImageIds.length}
      >
        <div className={Styles.ReportImageList}>
          <DragDropContext
            onBeforeDragStart={(): void => {
              tmpSeqNo = 0;
            }}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="droppable">
              {(
                provided: DroppableProvided
              ): React.ReactElement<HTMLElement> => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {orderImages.map(renderRow)}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </BulkActionsDropdown>
      {handleBulkDelete && (
        <ConfirmModal
          handleClose={handleCloseBulkDelete}
          handleSubmit={(): void => {
            handleCloseBulkDelete();
            handleBulkDelete();
          }}
          headerTitle="一括削除"
          isOpen={isOpenBulkDelete}
          submitLabel="削除"
          useDefaultFooterCancel
        >
          {`${selectedImageIds.length}件の画像を削除してよろしいですか？`}
        </ConfirmModal>
      )}
    </div>
  );
};
