import { number, object } from 'yup';
import {
  DealConstructionBase,
  DealConstructionContextBase
} from '~/models/DealConstruction';
import { DealSettlement } from './DealSettlement';

export type BillingType = 'owner' | 'rentee';
export type BillingTemplateType = 'deal_billing_owner' | 'deal_billing_rentee';
export type DealBilling = DealConstructionBase & {
  type: 'DealBilling';
  billingType: BillingType;
};

export type DealBillingContext = DealConstructionContextBase & {
  sumOwnerAmount: number;
  sumOwnerAmountWithTax: number;
  sumRenteeAmount: number;
  sumRenteeAmountWithTax: number;
  dealSettlements: DealSettlement[];
  settlementOwnerTotalAmount: number;
  settlementOwnerTotalAmountWithTax: number;
  settlementRenteeTotalAmount: number;
  settlementRenteeTotalAmountWithTax: number;
  type: 'DealBillingContext';
};

export type BillingBulkUpdateContext = {
  scale: number;
  truncateScale: number;
};

export const buildDefaultContext = (): BillingBulkUpdateContext => ({
  scale: 1.2,
  truncateScale: 2
});

export const validationSchemeBulkUpdateContext = object().shape({
  scale: number()
    .min(0, '0以上で入力してください')
    .max(2, '2以下で入力してください')
    .required('倍率を入力してください')
});
