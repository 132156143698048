import React from 'react';
import { RiToolsFill } from 'react-icons/ri';
import { useOpenHandler } from '~/hooks/useOpenHandler';
import { Deal } from '~/models/Deal';
import Styles from '../DealCard.module.scss';
import { EditMenuButton } from '~/components/common/parts/button/EditMenuButton';
import { DealRepairCardModal } from './DealRepairCardModal';
import { getModelNumberFilePath as clientNumberFilePath } from '~/utils/api/deals/DealRepair';
import { getModelNumberFilePath as vendorNumberFilePath } from '~/utils/api/vendors/deals/DealRepair';
import { FINISHED, OWN_FINISHED } from '~/models/Status';

type Props = {
  deal: Deal;
  companyType: 'client' | 'vendor';
  handleMutate?: (() => Promise<void>) | null;
};

const isEmpty = (value: string | unknown): boolean =>
  value == null || value === '';

const renderNotYetInput = (): React.ReactNode => (
  <span className={Styles.NotYet}>未入力</span>
);

export const DealRepairCard: React.FC<Props> = ({
  deal,
  companyType,
  handleMutate = null
}: Props) => {
  const { isOpen, handleOpen, handleClose } = useOpenHandler();
  const repair = deal.dealRepair;
  return (
    <div className={Styles.DealCard}>
      <h3 className={Styles.Title}>
        <RiToolsFill className={Styles.TitleIcon} />
        修繕情報
        {companyType === 'client' && (
          <div className={Styles.EditButtonWrapper}>
            {deal.status !== FINISHED && deal.status !== OWN_FINISHED && (
              <EditMenuButton onClick={handleOpen}>変更</EditMenuButton>
            )}
            <DealRepairCardModal
              deal={deal}
              handleClose={handleClose}
              handleMutate={handleMutate}
              isOpen={isOpen}
            />
          </div>
        )}
      </h3>
      <div className={Styles.Content}>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>依頼内容</dt>
          <dd className={Styles.ListContentValue}>
            <p className={Styles.longSentence}>
              {isEmpty(repair?.requestContent)
                ? renderNotYetInput()
                : repair?.requestContent}
            </p>
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>カテゴリー</dt>
          <dd className={Styles.ListContentValue}>
            <p>
              {isEmpty(repair?.itemCategory)
                ? renderNotYetInput()
                : repair?.itemCategory}
            </p>
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>商品名</dt>
          <dd className={Styles.ListContentValue}>
            <p>
              {isEmpty(repair?.itemName)
                ? renderNotYetInput()
                : repair?.itemName}
            </p>
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>メーカー</dt>
          <dd className={Styles.ListContentValue}>
            <p>
              {isEmpty(repair?.manufacturer)
                ? renderNotYetInput()
                : repair?.manufacturer}
            </p>
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>型番</dt>
          <dd className={Styles.ListContentValue}>
            <p>
              {isEmpty(repair?.modelNumber)
                ? renderNotYetInput()
                : repair?.modelNumber}
            </p>
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>現地調査希望</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(deal.conductFieldSurvey) ? (
              <>{renderNotYetInput()}</>
            ) : (
              <>{deal.conductFieldSurvey ? 'あり' : 'なし'}</>
            )}
          </dd>
        </dl>
        <dl className={Styles.ListContent}>
          <dt className={Styles.ListContentCaption}>型番画像</dt>
          <dd className={Styles.ListContentValue}>
            {isEmpty(repair?.modelNumberFile) ? (
              renderNotYetInput()
            ) : (
              <>
                <a
                  className={Styles.Link}
                  href={
                    companyType === 'client'
                      ? clientNumberFilePath(deal.id)
                      : vendorNumberFilePath(deal.id)
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  型番画像
                </a>
              </>
            )}
          </dd>
        </dl>
      </div>
    </div>
  );
};
