import React from 'react';
import { Box, Grid, Text } from '@itandi/itandi-bb-ui';
import { Deal } from '~/models/Deal';
import Breadcrumbs, {
  BreadcrumbItem
} from '~/components/common/parts/breadcrumb/Breadcrumbs';
import Styles from './SimpleHeader.module.scss';
import { DealTypeLabel } from '~/components/common/parts/label/DealTypeLabel';

type Props = {
  deal: Deal;
  breadcrumbItems: BreadcrumbItem[];
};

export const SimpleHeaderVendorSP: React.FC<Props> = ({
  deal,
  breadcrumbItems
}: Props) => (
  <Box position="sticky" top={0} width="100%">
    <div className={Styles.SimpleHeader} id="deal_header">
      <Box>
        <Breadcrumbs breadcrumbItems={breadcrumbItems} />
        <Grid autoFlow="column" justifyContent="start">
          <Box margin=".25rem">
            <DealTypeLabel
              dealSubtype={deal.dealSubtype}
              dealType={deal.dealType}
            />
          </Box>
          <Box>
            <Text size="L" weight="Bold">
              {deal.name} {deal.roomNumber}
            </Text>
          </Box>
        </Grid>
      </Box>
    </div>
  </Box>
);
