import React from 'react';
import { RiToolsFill } from 'react-icons/ri';
import { Input, ItandiChip, InputText, InputRadio } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import Styles from './Block.module.scss';
import { DealParam, requestContentPlaceholder } from '~/models/DealForm';

export const RepairBlock: React.FC = () => {
  const { register, formState, watch, setValue, getValues } =
    useFormContext<DealParam>();
  const { errors } = formState;
  const modelNumberFile: File | null = watch('dealRepair.modelNumberFile');
  const onChangeFile = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      name: 'dealRepair.modelNumberFile'
    ): void => {
      const { files } = e.target;
      if (files !== null) {
        const uploadFile: File = files[0];
        setValue(name, uploadFile);
      }
    },
    [setValue]
  );
  const onClickFileLink = React.useCallback(
    (e: React.MouseEvent, name: 'dealRepair.modelNumberFile'): void => {
      const file = getValues(name);
      if (file !== null) {
        e.preventDefault();
        const url = URL.createObjectURL(file);
        window.open(url);
      }
    },
    [getValues]
  );
  return (
    <div className={Styles.Block}>
      <div className={Styles.BlockCaptionWrapper}>
        <div className={Styles.BlockCaption}>
          <span className={Styles.BlockIconWrapper}>
            <RiToolsFill className={Styles.BlockIcon} />
          </span>
          <span className={Styles.BlockCaptionLabel}>修繕情報</span>
        </div>
      </div>
      <div className={Styles.BlockForms}>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>依頼内容</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <InputText
                isInvalid={errors.dealRepair?.requestContent != null}
                placeholder={requestContentPlaceholder}
                rows={7}
                {...register('dealRepair.requestContent')}
              />
            </div>
            {errors.dealRepair?.requestContent != null && (
              <div className={Styles.ErrorMessage}>
                {errors.dealRepair.requestContent.message}
              </div>
            )}
            <div className={Styles.Description}>
              施工許可の確認が不要となる金額等がある場合にはご記載ください。
              <br />
              例：税込33,000円以下の見積金額の場合、当日施工をお願いします
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>現地調査希望の有無</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputRadioGroup}>
              <InputRadio
                isInvalid={errors.conductFieldSurvey != null}
                label="あり"
                value="true"
                {...register('conductFieldSurvey')}
              />
              <InputRadio
                isInvalid={errors.conductFieldSurvey != null}
                label="なし"
                value="false"
                {...register('conductFieldSurvey')}
              />
            </div>
            {errors.conductFieldSurvey != null && (
              <div className={Styles.ErrorMessage}>
                {errors.conductFieldSurvey.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>商品のカテゴリー</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="必須" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.dealRepair?.itemCategory != null}
                placeholder="例）エアコン"
                {...register('dealRepair.itemCategory')}
              />
            </div>
            {errors.dealRepair?.itemCategory != null && (
              <div className={Styles.ErrorMessage}>
                {errors.dealRepair.itemCategory.message}
              </div>
            )}
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>商品名</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.dealRepair?.itemName != null}
                placeholder="例）霜ヶ峰 OOシリーズ 6畳用 "
                {...register('dealRepair.itemName')}
              />
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>メーカー</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.dealRepair?.manufacturer != null}
                placeholder="例）OO電機"
                {...register('dealRepair.manufacturer')}
              />
            </div>
          </div>
        </div>
        <div className={Styles.FormRow}>
          <div className={Styles.FormLabel}>
            <span className={Styles.FormLabelText}>型番</span>
            <span className={Styles.RequiredLabel}>
              <ItandiChip label="任意" />
            </span>
          </div>
          <div className={Styles.FormContent}>
            <div className={Styles.InputWrapper}>
              <Input
                isInvalid={errors.dealRepair?.modelNumber != null}
                placeholder="例）ABC-1234"
                {...register('dealRepair.modelNumber')}
              />
              <div className={Styles.InputFile}>
                {modelNumberFile != null && (
                  <div>
                    <a
                      className={Styles.FileLink}
                      href="#"
                      onClick={(e: React.MouseEvent): void =>
                        onClickFileLink(e, 'dealRepair.modelNumberFile')
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      {modelNumberFile.name}
                    </a>
                  </div>
                )}
                <div className={Styles.InputWrapper}>
                  <input
                    accept="image/*"
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>
                    ): void => {
                      onChangeFile(e, 'dealRepair.modelNumberFile');
                    }}
                    type="file"
                  />
                </div>
                {errors.dealRepair?.modelNumberFile != null && (
                  <div className={Styles.ErrorMessage}>
                    {errors.dealRepair.modelNumberFile.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
