import React from 'react';
import { useDeals } from '~/hooks/useDeals';
import { useMessages } from '~/hooks/deals/useMessages';
import Loader from '~/components/common/parts/loading/Loading';
import { BreadcrumbItem } from '~/components/common/parts/breadcrumb/Breadcrumbs';
import { PATH as DEALS_PATH } from '~/pages/deals';
import { PATH as DEAL_PATH } from '~/pages/deals/[id]';
import { DefaultV2 } from '~/components/common/layouts/DefaultV2';
import { DealLayout } from '~/components/common/layouts/deals/DealLayout';
import { ChatPageContainer } from '~/components/common/chat/ChatPageContainer';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';

export const Page: React.FC = () => {
  const id = useIdQueryHandler();
  const { data: deal, isLoading: isLoadingDeal } = useDeals().show(id);
  const {
    data: messages,
    mutate: mutateMessages,
    isLoading: isLoadingMessages
  } = useMessages().index(id);
  const handleMutate = React.useCallback(async () => {
    mutateMessages && (await mutateMessages());
  }, [mutateMessages]);
  if (
    isLoadingDeal ||
    isLoadingMessages ||
    deal === null ||
    messages === null
  ) {
    return <Loader isLoading />;
  }
  const breadcrumbItems: BreadcrumbItem[] = [
    { path: DEALS_PATH, caption: '案件一覧', active: false },
    { path: DEAL_PATH(deal.id), caption: '案件詳細', active: false },
    { path: null, caption: '施工会社とチャット', active: true }
  ];
  return (
    <DefaultV2>
      <DealLayout
        breadcrumbItems={breadcrumbItems}
        deal={deal}
        isVendor={false}
        useSimpleSpHeader
      >
        <ChatPageContainer
          deal={deal}
          handleMutate={handleMutate}
          messages={messages}
        />
      </DealLayout>
    </DefaultV2>
  );
};
