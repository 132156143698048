import React, { ReactNode } from 'react';
import { Flex, InputCheckBox } from '@itandi/itandi-bb-ui';
import { useFormContext } from 'react-hook-form';
import {
  NotificationEdit,
  NotificationLineSetting
} from '~/models/Notification';
import Styles from '../NotificationModal.module.scss';
import { LineUserAccount } from '~/models/LineUserAccount';

type Props = DeepReadonly<{
  lineUserAccounts: LineUserAccount[] | null;
}>;
export const NotificationLineSettingsCheckBox: React.FC<Props> = ({
  lineUserAccounts
}: Props) => {
  const { watch, formState, setValue } = useFormContext<NotificationEdit>();
  const { errors } = formState;
  if (lineUserAccounts === null) {
    return null;
  }
  const notificationLineSettings = watch('notificationLineSettings');
  const onChangeCheckBox = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      lineUserAccountId: number
    ): void => {
      const { checked } = e.target;
      if (checked) {
        setValue(
          'notificationLineSettings',
          notificationLineSettings.concat({ lineUserAccountId })
        );
      } else {
        setValue(
          'notificationLineSettings',
          notificationLineSettings.filter(
            (notificationLineSetting) =>
              notificationLineSetting.lineUserAccountId !== lineUserAccountId
          )
        );
      }
    },
    [notificationLineSettings, setValue]
  );
  const findNotificationLineSetting = (
    lineUserAccountId: number
  ): DeepReadonlyObject<NotificationLineSetting> | null =>
    notificationLineSettings.find(
      (notificationLineSetting) =>
        notificationLineSetting.lineUserAccountId === lineUserAccountId
    ) ?? null;

  return (
    <>
      <Flex direction="column" rowGap={2}>
        {lineUserAccounts.map(
          (lineUserAccount: LineUserAccount): ReactNode => (
            <InputCheckBox
              key={lineUserAccount.id}
              checked={!!findNotificationLineSetting(lineUserAccount.id)}
              isInvalid={errors.notificationLineSettings != null}
              labelText={lineUserAccount.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                onChangeCheckBox(e, lineUserAccount.id)
              }
            />
          )
        )}
      </Flex>
      {lineUserAccounts.length === 0 && (
        <div className={Styles.InvalidFeedback}>
          LINEアカウント設定から通知先を登録してください
        </div>
      )}
      {errors.notificationLineSettings != null && (
        <div className={Styles.InvalidFeedback}>
          {errors.notificationLineSettings.message}
        </div>
      )}
    </>
  );
};
