import { NskResponse } from '~/models/Api';
import { postRequest, putRequest } from '~/utils/api/api_handler';

import { DealVendorNoteParam } from '~/models/DealVendorNote';

const paths = {
  create: '/vendors/deals/:deal_id/deal_vendor_notes',
  update: '/vendors/deals/:deal_id/deal_vendor_notes/:id'
};

export const create = async (
  dealVendorNoteParams: DealVendorNoteParam,
  dealId: number
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    note: dealVendorNoteParams.note
  });

export const update = async (
  dealVendorNoteParams: DealVendorNoteParam,
  dealVendorNoteId: number,
  dealId: number
): Promise<NskResponse<void>> =>
  putRequest(
    paths.update
      .replace(':id', String(dealVendorNoteId))
      .replace(':deal_id', String(dealId)),
    { note: dealVendorNoteParams.note, version: dealVendorNoteParams.version }
  );
