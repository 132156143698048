import { NskResponse } from '~/models/Api';
import { BillingType } from '~/models/DealBilling';
import { postRequest } from '~/utils/api/api_handler';

const paths = {
  create: '/deals/:deal_id/deal_billing_generates'
};

export const create = async (
  dealId: number,
  billingType: BillingType | null
): Promise<NskResponse<void>> =>
  postRequest(paths.create.replace(':deal_id', String(dealId)), {
    billingType
  });
