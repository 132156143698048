import React from 'react';
import { ConstructionNavigation } from '~/components/common/constructions/navigation/ConstructionNavigation';
import { PATH } from '~/pages/vendors/deals/[id]/estimations';
import { useDealEstimations } from '~/hooks/vendors/deals/useDealEstimations';
import { UploadFileNavigation } from '~/components/common/upload_files/UploadFileNavigation';
import { NskResponse } from '~/models/Api';
import { create as uploadReceiptFile } from '~/utils/api/vendors/deals/DealReceiptFile';
import { destroy as destroyReceiptFile } from '~/utils/api/vendors/deals/DealUploadedFile';
import { DealUploadFile } from '~/models/DealUploadFile';
import { StatusHandleVendorButtons } from '~/components/common/statuses/StatusHandleVendorButtons';
import { create as createDealConstructionSchedule } from '~/utils/api/vendors/DealConstructionSchedule';
import {
  AWAITING_ACCEPTANCE,
  IN_CONSTRUCTION,
  IN_ESTIMATION,
  statusMovingValidation
} from '~/models/Status';
import { ScheduleInputNavigation } from '~/components/common/construction_schedule/ScheduleInputNavigation';
import { useDealCompletionReportImages } from '~/hooks/vendors/deals/useDealCompletionReportImages';
import { ReportImageNavigation } from '~/components/common/reports/navigation/ReportImageNavigation';
import { PATH as INSPECTION_REPORT_PATH } from '~/pages/vendors/deals/[id]/inspection_reports';
import { PATH as COMPLETION_REPORT_PATH } from '~/pages/vendors/deals/[id]/completion_reports';
import {
  getOriginDealVendor,
  isOriginVendor
} from '~/models/DealVendorCompany';
import { useSessions } from '~/hooks/vendors/useSessions';
import { getFilePath as getVendorFilePath } from '~/utils/api/vendors//deals/DealUploadedFile';
import { useDeals } from '~/hooks/vendors/useDeals';
import { useIdQueryHandler } from '~/hooks/useIdQueryHandler';
import { useDealInspectionReportImages } from '~/hooks/vendors/deals/useDealInspectionReportImages';
import { matchDealType } from '~/models/Deal';
import { DealConstructionScheduleEdit } from '~/models/DealConstructionSchedule';

export const InConstruction: React.FC = () => {
  const dealId = useIdQueryHandler();
  const { data: deal, mutate: mutateDeal } = useDeals().show(dealId);
  const { data: estimationContext } = useDealEstimations().index(dealId);
  const { data: inspectionImages } =
    useDealInspectionReportImages().index(dealId);
  const { data: completeImages } =
    useDealCompletionReportImages().index(dealId);
  const { data: session } = useSessions().index();
  const handleMutateDeal = async (): Promise<void> => {
    mutateDeal && (await mutateDeal());
  };
  const forwardStatusEnabled = React.useMemo(() => {
    if (
      deal &&
      getOriginDealVendor(deal)?.skipEstimation &&
      completeImages &&
      completeImages.length > 0
    )
      return true;
    if (inspectionImages === null || completeImages === null) return true;
    return statusMovingValidation[IN_CONSTRUCTION](
      inspectionImages,
      completeImages,
      deal?.completionReportImageRequired ?? true
    );
  }, [completeImages, deal, inspectionImages]);
  if (
    deal === null ||
    session === null ||
    dealId === null ||
    inspectionImages === null ||
    completeImages === null
  ) {
    return null;
  }
  const handleSubmitDealConstructionSchedule = async (
    schedules: DealConstructionScheduleEdit[]
  ): Promise<NskResponse<void>> =>
    createDealConstructionSchedule(deal.id, schedules);
  const handleDestroyReceipt = async (
    file: DealUploadFile
  ): Promise<NskResponse<void>> => destroyReceiptFile(deal.id, file.id);
  const handleUploadReceipt = async (file: File): Promise<NskResponse<void>> =>
    uploadReceiptFile(file, deal.id);
  const handleCreateReceipt = async (): Promise<NskResponse<void>> =>
    uploadReceiptFile(null, deal.id);
  const handleGetReceiptPath = (file: DealUploadFile): string =>
    getVendorFilePath(deal.id, file);
  const showInspectionImagePanel =
    matchDealType(deal, 'repair') && !deal.conductFieldSurvey;
  const enabledTemplate =
    session.vendorCompany.enabledDealReceiptFileTemplate ?? null;
  return (
    <div>
      {estimationContext !== null && (
        <ConstructionNavigation
          dealConstructionContext={estimationContext}
          detailPagePath={PATH(deal.id)}
          title="施工項目が確定しました"
        />
      )}
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <UploadFileNavigation
          CreateSubmitLabel={enabledTemplate ? '発注書請書を作成' : null}
          deal={deal}
          files={deal.dealReceiptFiles}
          fileType="工事発注書・請書"
          handleCreate={enabledTemplate ? handleCreateReceipt : null}
          handleDestroy={handleDestroyReceipt}
          handleGetFilePath={handleGetReceiptPath}
          handleMutate={handleMutateDeal}
          handleUpload={handleUploadReceipt}
          title="工事発注書・請書を登録してください"
        />
      )}
      <ScheduleInputNavigation
        deal={deal}
        handleMutate={handleMutateDeal}
        handleSubmit={handleSubmitDealConstructionSchedule}
      />
      {showInspectionImagePanel && (
        <ReportImageNavigation
          detailPageButtonLabel="画像登録画面"
          detailPagePath={INSPECTION_REPORT_PATH(dealId)}
          reportImages={inspectionImages}
          title="施工前の画像を登録してください"
        />
      )}
      <ReportImageNavigation
        detailPageButtonLabel="画像登録画面"
        detailPagePath={COMPLETION_REPORT_PATH(dealId)}
        optionalLabel={!deal.completionReportImageRequired}
        reportImages={completeImages}
        title="完了報告画像を登録してください"
      />
      {isOriginVendor(deal, session.vendorCompany.id) && (
        <StatusHandleVendorButtons
          forwardDisabled={!forwardStatusEnabled}
          nextStatusId={AWAITING_ACCEPTANCE}
          prevStatusId={IN_ESTIMATION}
        />
      )}
    </div>
  );
};
