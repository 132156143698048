import { object, string } from 'yup';

export type DealVendorNote = {
  id: number;
  note: string;
  noteHtml: string;
  version: number;
  vendorUser: { name: string; vendorCompany: { name: string } } | null;
};
export type DealVendorNoteForDisplay = {
  id: number;
  noteHtml: string;
  vendorUser: { name: string; vendorCompany: { name: string } } | null;
};
export type DealVendorNoteTemplate = {
  dealVendorNoteTemplate: string | null;
};
export type DealVendorNoteParam = {
  note: string;
  version: number | null;
};
export const buildDealVendorNoteParam = (
  dealVendorNote: DealVendorNote | null
): DealVendorNoteParam => ({
  note: dealVendorNote?.note ?? '',
  version: dealVendorNote ? dealVendorNote.version + 1 : null
});
export const validationSchema = object().shape({
  note: string().max(10000, '10000文字以内で設定してください')
});

export const displayUpdateUserName = (
  dealVendorNote: DealVendorNote | DealVendorNoteForDisplay
): string =>
  dealVendorNote?.vendorUser
    ? `${dealVendorNote.vendorUser.vendorCompany.name} ${dealVendorNote.vendorUser.name}`
    : '管理者';
